/**
 * The ApiManager is responsible for handling all http requests. 
 * Additionally, handles subscription to server-sent events.
 */

import axios, {AxiosInstance, AxiosResponse} from "axios";
import * as StorageManager from "../datamanagers/StorageManager";
import { loadingIndicator } from "@/datamanagers/ReactiveStates";
import * as AlertManager from '@/datamanagers/AlertsManager';
import { connectionStates } from "@/datamanagers/StateIndicatorsManager";
import { prod_safe_log } from '@/utils';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { useConfig } from "@/main";
import i18n from "@/i18n";

const https = require('https');

const apiEndpoints = {
  map:{
    add_node_path: '2.0/map_configs/node',
    add_edge_path: '2.0/map_configs/edge',
    add_action_path: '2.0/map_configs/action',
    edit_edge_path: '2.0//map_configs/edge',
    edit_node_path: '2.0/map_configs/node',
    get_nodes_path: '2.0/map_configs/nodes',
    get_edges_path: '2.0/map_configs/edges',
    get_actions_path: '2.0/map_configs/actions',
    fit_trajectory: '2.0/map_configs/fit_trajectory',
    get_all_connected_components_path: '2.0/map_configs/connected_components',
    connected_component_path: '2.0/map_configs/connected_component'
  },
  auth: {
    login: '2.0/auth/login',
    license_activate: '2.0/license/activate',
  },
  user: {
    api_user_path: '2.0/user',
    api_users_list_path: '2.0/user/all',
    api_role_path: '2.0/user/role',
    api_roles_list_path: '2.0/user/role/all',
  },
  order: {
    add_order_path: '2.0/order',
    get_orders_path: '2.0/order/orders',
    add_order_request_path: '2.0/order_request',
    get_order_requests_path: '2.0/order_request/unprocessed_order_requests',
  },
  order_template: {
    order_template_path: '2.0/order_template',
    get_all_order_templates_path: '2.0/order_template/all',
  },
  trigger: {
    get_all_triggers: '2.0/triggers/template/all',
    triggers: '2.0/triggers/template',
    send_trigger: '2.0/triggers/waiting_vehicle',
  },
  configs: {
    add_config_path: '2.0/configs',
    get_latest_config_version_path: '2.0/configs/latest_version',
    get_latest_config_path: '2.0/configs/latest',
  },
  sse_stream: {
    subscribe: '2.0/stream/subscribe'
  }
}

var apiClient: AxiosInstance;
const events = {
  eventListeners: new Map(),
  addEventListener(eventName: string, callback: Function) {
    // We are adding event callbacks by name of the event and callback name.
    // if there is not callback name set - it can cause duplicate callback triggering or rewrite the existing callback without name.
    // ENSURE YOU PASS EVENT CALLBACK BY REFERENCE WITH UNIQUE NAME
    const storedListeners = this.eventListeners.get(eventName) || [];
    const existingCallbackIndex = storedListeners.findIndex((elem:any) => elem.name === callback.name);
    if (existingCallbackIndex < 0) {
      storedListeners.push(callback);
    } else {
      storedListeners[existingCallbackIndex] = callback;
    };
    this.eventListeners.set(eventName, storedListeners);
  }
} as any;

let globalTimeout: number;

// Initialises axios instance, sets jwt token when it is present in local storage.
// Inits EventSource and subscribes to error messages.
function initApiClient(apiUrl: string, protocol: string) {
  globalTimeout = globalTimeout = useConfig().localConfig.global_api_request_timeout || useConfig().config.configuration.global_api_request_timeout || 5000;
  if (!apiClient) {
    console.log("Initing api, host: ", apiUrl)
    if (protocol === "https:") {
      axios.defaults.httpsAgent = new https.Agent({
        rejectUnauthorized: true, // set to false when having troubles with certificates
      })
    }
    console.log('timeout:',  globalTimeout)
    apiClient = axios.create({
      baseURL: apiUrl, //leitstelle_api_url,
      timeout: globalTimeout,
      headers: {"Content-Type": "application/json"}
    });

    apiClient.interceptors.request.use(req => {
      const headers = req.headers
      if (headers) {
        const token = StorageManager.getJwt();
        if (token) {
          headers.authorization = `Bearer ${token}`;
        }
      }
      return req;
    });
  }

  let ssePulse: any;

  if (!events.eventSource){
    const token = StorageManager.getJwt();
    if (token) {
      try {
        let sseCtrl = new AbortController();
        const subscribe = async () => {
          if (ssePulse === undefined) {
            ssePulse = setInterval(() => {
              if (connectionStates.is_api_sse_active) {
                clearInterval(ssePulse);
                return ssePulse = undefined;
              }

              console.log('SSE Pulse');
              const uri = `${apiUrl}stream`;
              sseCtrl.abort();
              sseCtrl = new AbortController();
              events.eventSource = fetchEventSource(uri, {
                headers: {
                  'Authorization': 'Bearer '+token,
                },
                signal: sseCtrl.signal,
                onmessage(ev) {
                  console.log('Received SSE message', ev);
                  const eventListeners = events.eventListeners.get(ev.event);
                  if (eventListeners !== undefined && eventListeners.length){
                    eventListeners.forEach((callback: Function) => callback(ev));
                  }
                },
                async onopen(response: any) {
                  // Some logic when connection is opened
                  console.info("Connected to sse")
                  connectionStates.is_api_sse_active = true;
                  if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                    return; // everything's good
                  }
                },
                onclose() {
                  // if the server closes the connection unexpectedly, retry:
                  connectionStates.is_api_sse_active = false;
                  console.info("Disconnected from sse");
                  setTimeout(() => subscribe(), 100);
                },
                onerror(err) {
                  connectionStates.is_api_sse_active = false;
                  console.info("Error from sse", err);
                  setTimeout(() => subscribe(), 100);
                },
                openWhenHidden: true
              });

              const path = `${apiUrl + apiEndpoints.sse_stream.subscribe}`;
              setTimeout(() => generic_http_request(apiClient?.post(path), () => {},  () => {}), 500);
            }, useConfig().localConfig.sse_pulse_interval || globalTimeout || 1000);
            return;
          }
        };
        subscribe();
      } catch (e: any) {
        console.warn("Caught error while connecting to event source, ", e)
      }
    }
  }
}

// Use username or email and and password to get acces token, saves it and sets to authorisation header
function login(payload: {password: string} & ({username: string, email?: never} | {username?: never, email: string}), onError: any, onResult: Function) {
  // TODO adjust payload to match the new login functionality
  const apiPayload = {
    username: payload.username ? payload.username : payload.email,
    password: payload.password
  }

  generic_http_request(apiClient?.post(apiEndpoints.auth.login, apiPayload), (err: any) => (onError(err)),
    (res: AxiosResponse) => {
      const newToken = res.data.refresh_token
      StorageManager.setJwt(newToken)
      prod_safe_log("updated jwt")
      onResult(res.data)
    }
  )
}

// Calls api to activate license, stored in db on server
function activateLicense(onError: any, onResult: Function) {
    generic_http_request(apiClient?.get(apiEndpoints.auth.license_activate), onError, onResult)
}

// Base function to make http request, handles empy response, expired jwt error, any other error. 
// If the request is successful, forwards it to caller for processing 
function generic_http_request(request: any, onError: Function, processResponse: any, processEmptyResponse?: any) {
    if (request){
      loadingIndicator.counter++;
      request
        .then((res: AxiosResponse) => {
          if (res.status == 200 || res.status == 201) {
            processResponse(res);
          } else if (res.status === 204) {
            if (processEmptyResponse) processEmptyResponse();
          }
        })
        .catch((error: any) => {
          console.error("http error", error);
          if (error?.response?.status == 422 || error?.response?.status == 401) {
            apiClient.defaults.headers.common = {"Content-Type": "application/json"};
            StorageManager.removeJwt();
            StorageManager.removeUser();
            console.info("Need to re-login to get fresh jwt token");
            AlertManager.showAlert(AlertManager.createErrorAlert(i18n.t('str_need_to_relogin'), i18n.t("str_need_to_relogin_msg")))
          } else if (error?.response?.status === 403) {
            AlertManager.showAlert(AlertManager.createErrorAlert(i18n.t('str_access_restricted'), i18n.t('str_access_restricted_msg')))
          }
          onError(error);
        })
        .finally( () => {
          loadingIndicator.counter--;
        });
    } else {
      console.error("Cannot do http request, api client is not inited")
    }
}

// USER MANAGEMENT SECTION

function changeOwnPassword(payload: {password: string, new_password: string}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.user.api_user_path}/change_password`;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult);
}

function getAllUsers(onNoResult: Function, onResult: Function, onEmptyResult?: Function) {
  const path = apiEndpoints.user.api_users_list_path;
  generic_http_request(apiClient?.get(path), onNoResult, onResult, onEmptyResult);
}

function createUser(payload: any, onNoResult: Function, onResult: Function) {
  const path = apiEndpoints.user.api_user_path;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult);
}

function editUser(payload: any, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.user.api_user_path}/${payload.oldUsername}`;
  delete payload.oldUsername;
  generic_http_request(apiClient?.put(path, payload), onNoResult, onResult);
}

function deleteUser(username: string, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.user.api_user_path}/${username}`;
  generic_http_request(apiClient?.delete(path), onNoResult, onResult);
}

function getAllRoles(onNoResult: Function, onResult: Function, onEmptyResult?: Function) {
  const path = apiEndpoints.user.api_roles_list_path;
  generic_http_request(apiClient?.get(path), onNoResult, onResult, onEmptyResult);
}

function createRole(payload: any, onNoResult: Function, onResult: Function) {
  const path = apiEndpoints.user.api_role_path;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult);
}

function editRole(payload: any, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.user.api_role_path}/${payload.id}`;
  generic_http_request(apiClient?.put(path, payload), onNoResult, onResult);
}

function deleteRole(payload: {roleId: string | number, fallbackId: string | number}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.user.api_role_path}/${payload.roleId}?fallback_id=${payload.fallbackId}`;
  generic_http_request(apiClient?.delete(path), onNoResult, onResult);
}

// End of USER MANAGEMENT SECTION

// CONNECTED COMPONENT SECTION

function getAllConnectedComponents(onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.map.get_all_connected_components_path}`;
  generic_http_request(apiClient?.get(path), onNoResult, onResult);
}

function createConnectedComponent(payload: {edges: (number | string)[]},onNoResult: Function, onResult: Function, onEmptyResult?: Function) {
  const path = `${apiEndpoints.map.connected_component_path}`;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult, onEmptyResult);
}

function getConnectedComponent(payload: {component_id: number | string, edges: (number | string)[]}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.map.connected_component_path}/${payload.component_id}`;
  generic_http_request(apiClient?.post(path, {edges: payload.edges}), onNoResult, onResult);
}

function updateConnectedComponent(payload: {component_id: number | string, edges: number[]}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.map.connected_component_path}/${payload.component_id}`;
  generic_http_request(apiClient?.put(path, {edges: payload.edges}), onNoResult, onResult);
}

function deleteConnectedComponent(payload: {component_id: number | string}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.map.connected_component_path}/${payload.component_id}`;
  generic_http_request(apiClient?.delete(path), onNoResult, onResult);
}

// End of CONNECTED COMPONENT SECTION
// ADMINISTRATION SECTION

function saveConfiguration(payload: {config_file: string, filename: string}, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.configs.add_config_path}`;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult);
}

function getLatestConfigurationVersion(onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.configs.get_latest_config_version_path}`;
  generic_http_request(apiClient?.get(path), onNoResult, onResult);
}

function getLatestConfiguration(onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.configs.get_latest_config_path}`;
  generic_http_request(apiClient?.get(path), onNoResult, onResult);
}

// End of ADMINISTRATION SECTION

// Sends request to cancel a order by orderId
function cancelOrder(orderId:number, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.order.add_order_path}/${orderId}`
    generic_http_request(apiClient?.put(path, {canceled: true, end_time: new Date().toISOString()}), onNoResult, onResult)
}

function cancelOrderRequest(orderRequestId:string, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.order.add_order_request_path}/${orderRequestId}`
    generic_http_request(apiClient?.put(path, {processed: true}), onNoResult, onResult);
}

// Sends request to delete a order by orderId
function deleteOrder(orderId: string, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.order.add_order_path}/${orderId}`
    generic_http_request(apiClient?.delete(path), onNoResult, onResult)
}

// Sends request to delete a order by orderId
function deleteOrderRequest(orderRequestId: string, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.order.add_order_request_path}/${orderRequestId}`
    generic_http_request(apiClient?.delete(path), onNoResult, onResult)
}
// Gets a specific order from api
function getOrder(orderId: number, onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.order.add_order_path}/${orderId}`;
  const timeout = useConfig().localConfig.get_all_orders_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({order: "", length: 0}));
}
// Gets order list from api
function getAllOrders(pathParams: string, onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.order.get_orders_path}?${pathParams}`;
  const timeout = useConfig().localConfig.get_all_orders_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({orders: [], length: 0}));
}

// Gets a specific orderRequest from api
function getOrderRequest(orderReqId: number, onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.order.add_order_request_path}/${orderReqId}`;
  const timeout = useConfig().localConfig.get_all_orders_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({orderRequest: "", length: 0}));
}
// Gets order request list from api
function getAllOrderRequests(pathParams: string, onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.order.get_order_requests_path}?${pathParams}`;
  const timeout = useConfig().localConfig.get_all_order_requests_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({order_requests: []}));
}

// Gets custom triggers list from api
function getAllTriggers(onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.trigger.get_all_triggers}`;
  const timeout = useConfig().localConfig.get_all_order_requests_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({trigger_templates: []}));
}
// Creates custom trigger
function addTrigger(payload: any, onNoResult: Function, onResult: Function) {
  const path = apiEndpoints.trigger.triggers;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult)
}
// Updates custom trigger
function updateTrigger(triggerId: number, payload: any, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.trigger.triggers}/${triggerId}`;
  generic_http_request(apiClient?.put(path, payload), onNoResult, onResult)
}
// Deletes custom trigger
function deleteTrigger(triggerId: number, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.trigger.triggers}/${triggerId}`;
  generic_http_request(apiClient?.delete(path), onNoResult, onResult)
}
// Sends custom trigger
function sendCustomTrigger(payload: any, onNoResult: Function, onResult: Function) {
  const path = `${apiEndpoints.trigger.send_trigger}`;
  generic_http_request(apiClient?.post(path, payload), onNoResult, onResult)
}

// Gets vehicles list from api
function getAllVehicles(pathParams: string, onNoResult: Function, onResult: Function,) {
  const path = `${apiEndpoints.order.add_order_path}/vehicles?${pathParams}`;
  const timeout = useConfig().localConfig.get_all_vehicles_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({vehicles: []}));
}

// Creates new edge
function addEdge(payload: any, onNoResult: Function, onResult: Function) {
  const path = apiEndpoints.map.add_edge_path
    generic_http_request(apiClient?.post(path, payload), onNoResult, onResult)
  }

function editEdge(edgeId:number, payload: any, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.map.edit_edge_path}/${edgeId}`
    generic_http_request(apiClient?.put(path, payload), onNoResult, onResult)
}
function deleteEdge(edgeId:number, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.map.edit_edge_path}/${edgeId}`
    generic_http_request(apiClient?.delete(path), onNoResult, onResult)
}

// Creates new node
function addNode(payload: any, onNoResult: Function, onResult: Function) {
  const path = apiEndpoints.map.add_node_path
    generic_http_request(apiClient?.post(path, payload), onNoResult, onResult)
  }

function editNode(nodeId:number, payload: any, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.map.edit_node_path}/${nodeId}`
    generic_http_request(apiClient?.put(path, payload), onNoResult, onResult)
}

function deleteNode(nodeId:number, onNoResult: Function, onResult: Function) {
    const path = `${apiEndpoints.map.edit_node_path}/${nodeId}`
    generic_http_request(apiClient?.delete(path), onNoResult, onResult)
}

// Gets node list
function getNodes(onNoResult: Function, onResult: Function, params?: {page: number, page_size: number}) {
  let path = apiEndpoints.map.get_nodes_path;
  if (params !== undefined) {
    path += `?page=${params.page}&page_size=${params.page_size}`;
  }
  const timeout = useConfig().localConfig.get_nodes_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({nodes: []}));
}

function getEdges(onNoResult: Function, onResult: Function, params?: {page: number, page_size: number}) {
  let path = apiEndpoints.map.get_edges_path;
  if (params !== undefined) {
    path += `?page=${params.page}&page_size=${params.page_size}`;
  }
  const timeout = useConfig().localConfig.get_edges_timeout || globalTimeout;
  generic_http_request(apiClient?.get(path, {timeout}), onNoResult,
    (res: AxiosResponse) => onResult(res.data),
    () => onResult({edge: []}));
}

// Gets action list
function getActions(onNoResult: Function, onResult: Function,) {
    const path = apiEndpoints.map.get_actions_path
    generic_http_request(apiClient?.get(path), onNoResult,
        (res: AxiosResponse) => onResult(res.data),
        () => onResult({actions: []}))
}

// Types of messages sent by server
const sse_message_types = {
    order_list_updated : "order_list_updated",
    order_dispatched : "order_dispatched",
    order_created_with_errors : "order_created_with_errors",
    order_updated : "order_updated",
    order_canceled : "order_canceled",
    order_completed : "order_completed",
    order_deleted : "order_deleted",
    order_request_list_updated : "order_request_list_updated",
    order_request_added : "order_request_added",
    order_request_updated : "order_request_updated",
    order_request_processed : "order_request_processed",
    order_request_deleted : "order_request_deleted",
    job_finished : "job_finished",
    job_started: "job_started",
    job_cancelled: "job_cancelled",
    node_list_updated : "node_list_updated",
    node_updated : "node_updated",
    node_deleted : "node_deleted",
    edge_list_updated: "edge_list_updated",
    edge_updated: "edge_updated",
    edge_deleted: "edge_deleted",
    order_template_list_updated: "order_template_list_updated",
    trigger_template_added: "trigger_template_added",
    trigger_template_edited: "trigger_template_edited",
    trigger_template_removed: "trigger_template_removed",
    connected_component_added: "connected_component_added",
    connected_component_deleted: "connected_component_deleted",
    connected_component_edited: "connected_component_edited",
}

// Subscribes to EventSource with given calbacks
function subscribeToUpdates(messageCallbackArray: any) {
  for (let val in messageCallbackArray){
    let [msgType, callback] = messageCallbackArray[val]
      events?.addEventListener(msgType, callback, false);
  }
}

function createOrderRequest(nodeSeq: any[], startTime: string, vehicleId: string = "", orderRequestPriority: number,
                            onNoResult: Function, onResult: Function) {
    const payload = {
        "zoneSetId": "1",
        "start_time": startTime,
        "canceled": false,
        "vehicle_id": vehicleId,
        "nodes": nodeSeq,
        "priority": orderRequestPriority
    }
    generic_http_request(apiClient?.post(`${apiEndpoints.order.add_order_request_path}`, payload), onNoResult,
        (res: AxiosResponse) => onResult(res.data?.orderRequestId))
}

function saveOrderTemplate(newRoute: any, onNoResult: Function, onResult: Function) {
    const payload = {
        "nodes": newRoute.nodes,
    } as any;

    if (newRoute.title)
      payload.title = newRoute.title;

    if (newRoute.description)
      payload.description = newRoute.description;

    if (newRoute.category)
      payload.category = newRoute.category;

    generic_http_request(apiClient?.post(`${apiEndpoints.order_template.order_template_path}`, payload), onNoResult,
        (res: AxiosResponse) => onResult(res.data?.orderRequestId))
}

function getAllOrderTemplates(onNoResult: Function, onResult: Function) {
    generic_http_request(apiClient?.get(`${apiEndpoints.order_template.get_all_order_templates_path}`), onNoResult,
        (res: AxiosResponse) => onResult(res.data?.order_templates))
}

function deleteOrderTemplate(orderTemplateId: string, onNoResult: Function, onResult: Function) {
    generic_http_request(apiClient?.delete(`${apiEndpoints.order_template.order_template_path}/${orderTemplateId}`), onNoResult,
        (res: AxiosResponse) => onResult(res))
}

function updateOrderTemplate(orderTemplateId: string, payload: {title?: string, description?: string, category?: string, nodes?: any[]}, onNoResult: Function, onResult: Function) {
  console.log(payload)
    generic_http_request(apiClient?.put(`${apiEndpoints.order_template.order_template_path}/${orderTemplateId}`, payload), onNoResult,
        (res: AxiosResponse) => onResult(res))
}

// makes api call to fit NURBS curve to given points
function fitRawTrajectory(data:any, startPoint: {x:number, y:number}|undefined, endPoint: {x:number, y:number}|undefined, onNoResult: Function, onResult: Function){
    const payload = {
        "data": data,
        "start": startPoint,
        "end": endPoint
    }
    generic_http_request(apiClient?.post(apiEndpoints.map.fit_trajectory, payload), onNoResult,
        (res: AxiosResponse) => onResult(res.data?.curve))
}


export {
    getAllOrders,
    getAllOrderRequests,
    getAllVehicles,
    getOrder,
    getOrderRequest,
    createOrderRequest,
    addNode,
    editNode,
    deleteNode,
    addEdge,
    editEdge,
    deleteEdge,
    getNodes,
    getEdges,
    getActions,
    cancelOrder,
    cancelOrderRequest,
    deleteOrderRequest,
    deleteOrder,
    getAllTriggers,
    addTrigger,
    updateTrigger,
    deleteTrigger,
    sendCustomTrigger,
    initApiClient,
    login,
    changeOwnPassword,
    getAllUsers,
    createUser,
    editUser,
    deleteUser,
    getAllRoles,
    createRole,
    editRole,
    deleteRole,
    getAllConnectedComponents,
    createConnectedComponent,
    getConnectedComponent,
    updateConnectedComponent,
    deleteConnectedComponent,
    saveConfiguration,
    getLatestConfigurationVersion,
    getLatestConfiguration,
    activateLicense,
    subscribeToUpdates,
    sse_message_types,
    fitRawTrajectory,
    saveOrderTemplate,
    getAllOrderTemplates,
    deleteOrderTemplate,
    updateOrderTemplate
};
