<template>
  <div :id="`vehicle-${vehicleState?.name + '-' + vehicleId}-short-view`"
       class="flex-col h-full w-full">
    <div class="flex flex-row cursor-pointer">
      <div @mousedown="showTooltip = true" @touchstart="showTooltip = true" class="relative py-2 pl-2 flex items-center">
        <div v-if="showTooltip" class="absolute text-xs bg-white z-50 px-2 py-1 rounded rounded-bl-none capitalize ml-1" style="transform: translate(0, -100%)">{{ getVehicleIndicatorType(vehicleState) }}</div>
        <div v-if="getVehicleIndicatorType(vehicleState) !== 'emergencyStop' && getVehicleIndicatorType(vehicleState) !== 'waitingForTrigger'" :class="[getVehicleCheckColor(vehicleState),
                              'h-2 w-2 min-w-[0.5rem] my-4 px-0 rounded-full flex items-center']"/>
        <StopCircleIcon v-if="getVehicleIndicatorType(vehicleState) === 'emergencyStop'" class="h-4 w-4 min-w-[1rem] my-3 mx-[-0.25rem] px-0 text-red-500"/>
        <HourglassIcon v-if="getVehicleIndicatorType(vehicleState) === 'waitingForTrigger'" class="h-4 w-4 min-w-[1rem] my-3 mx-[-0.25rem] px-0 text-red-500 flex justify-center items-center"/>
      </div>
      <p @click="expanded = !expanded" class="flex p-2 color_enabled items-center min-w-[50%]" style=" vertical-align: middle;">
        {{ vehicleState?.name }}-{{ vehicleId }}
        <ExclamationTriangleIcon v-if="!expanded && typeof vehicleState?.agvPosition?.positionInitialized === 'boolean' && !vehicleState?.agvPosition?.positionInitialized" class="w-5 h-5 ml-1 text-orange-500"/>
      </p>
      <div class="flex flex-grow"/>
      <p @click="expanded = !expanded" class="px-2 py-1 color_enabled align-bottom" style="font-size: 0.9rem;  line-height:25px;">
        {{ $t("str_last_updated") }}:
        {{ formatTimeString(Number(vehicleState?.timestamp)) }}</p>
    </div>
    <!--    change bg-transparent to bg-red-500 to see when vehicle makes base request-->
    <div :class="[vehicleState?.newBaseRequest ? 'bg-transparent' : 'bg-transparent', 'flex flex-col m-2']"
         v-if="expanded">
      <ul>
        <li v-if="getVehicleIndicatorType(vehicleState) === 'waitingForTrigger'" class="flex flex-row flex-grow mx-8 mb-4">
          <button @click="showTriggerPopup = true" class="mx-8 border w-full rounded-md py-1 button2">
            Send Trigger
          </button>
        </li>
        <li class="flex flex-row flex-grow mx-8 mb-4">
          <button @click="findVehicleOnMap" class="mx-8 border w-full rounded-md py-1 button2">
            <!-- <div class="relative py-2 pl-2 flex items-center" @click="findVehicleOnMap">
              <MapPinIcon class="h-4 w-4 min-w-[1rem] px-0 color_enabled"/>
            </div> -->
            {{ $t('str_find_on_map')}}
          </button>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_show_on_map") }}:
          </p>
          <div class="flex flex-grow"/>
          <Switch
              @click="toggleVehicleVisibility()"
              v-model="vehicleState.show_on_map"
              :class="vehicleState.show_on_map ? 'bg-orange-100' : 'bg-teal-900'"
              class="relative h-4 w-8 mx-2 shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-100 ease-in-out">
            <span class="sr-only">{{ $t("str_show_on_map") }}</span>
            <span
                aria-hidden="true"
                :class="vehicleState.show_on_map ? 'translate-x-2' : '-translate-x-2'"
                class="inline-block h-4 w-4 transform rounded-full -translate-y-0.5 bg-white shadow-lg transition duration-100 ease-in-out"
            />
          </Switch>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_order_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.orderId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_node_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.lastNodeId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_sequence_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.lastNodeSequenceId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_localized") }}:
          </p>
          <PencilIcon v-if="enableVehicleLocalization && (vehicleState?.connected && liveViewEditorState.vehilceLocalization.vehicleId !== vehicleId)" class="ml-1 h-4 w-4 color_enabled cursor-pointer" aria-hidden="true" v-on:click="() => $emit('activateVehicleLocalization', vehicleId)"/>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled flex items-center">
            <ExclamationTriangleIcon v-if="typeof vehicleState?.agvPosition?.positionInitialized === 'boolean' && !vehicleState?.agvPosition?.positionInitialized" class=" mr-1 w-5 h-5 text-orange-500"/>
            {{ vehicleState?.agvPosition?.positionInitialized ?? false }}
          </p>
        </li>

        <li v-if="liveViewEditorState.vehilceLocalization.vehicleId === vehicleId" class="flex flex-row flex-grow ml-2">
          <div class="w-1/3 px-2">
            <p class="mb-1 color_enabled">
              x
            </p>
            <input class="mb-1 w-full rounded" v-model="newVehiclePosition.x" type="number" step="0.01">
          </div>
          <div class="w-1/3 px-2">
            <p class="mb-1 color_enabled">
              y
            </p>
            <input class="mb-1 w-full rounded" v-model="newVehiclePosition.y" type="number" step="0.01">
          </div>
          <div class="w-1/3 px-2">
            <p class="mb-1 color_enabled">
              theta
            </p>
            <input class="mb-1 w-full rounded" v-model="newVehiclePosition.theta" type="number" step="0.01">
          </div>
        </li>
        <li v-if="liveViewEditorState.vehilceLocalization.vehicleId === vehicleId" class="flex flex-row flex-grow ml-4">
          <p class="my-1 color_enabled">
            MapId:
          </p>
          <input class="my-1 ml-2 w-1/3 rounded" v-model="newVehiclePosition.mapId">
        </li>
        <li v-if="liveViewEditorState.vehilceLocalization.vehicleId === vehicleId" class="flex flex-row flex-grow mx-4 mb-3 mt-2">
          <button @click="() => $emit('activateVehicleLocalization', '')" class="border w-1/2 rounded-md py-1 button2">
            Cancel
          </button>
          <button @click="saveLocalization()" class="ml-2 -mr-2 border w-1/2 rounded-md py-1 button2">
            Confirm
          </button>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_driving") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.driving }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_paused") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.paused }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_new_base_request") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.newBaseRequest }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_has_released_edges") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.edgeStates.filter((value: EdgeState) => value.released).length }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_has_released_nodes") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.nodeStates.filter((value: NodeState) => value.released).length }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_battery") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ Math.trunc(Number(vehicleState?.batteryState?.batteryCharge)) }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_current_destination[0]") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ $t("str_current_destination[1]") }} {{ vehicleState?.nodeStates?.at(-1)?.node_id }}, {{
              NodesManager.nodes.get(Number(vehicleState?.nodeStates?.at(-1)?.node_id))?.nodeDescription
            }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 mb-2 mt-4 truncate text-sm color_disabled font-light">
            {{ $t("str_instant_actions") }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <div class="w-full mx-4 mb-2">
            <Dropdown
                :elem-list="actionTypes"
                @selectedEl="setInstantActionType"/>
          </div>
        </li>
        <div v-if="instantAction.actionParameters.length !== 0" v-for="actionParam in instantAction.actionParameters">
          <ul>
            <li class="flex flex-row flex-grow">
              <p class="mx-4 mb-2 flex justify-between" style="width: calc(100% - 32px);">
                <span v-if="actionParam.isEditing"><input class="w-full px-2" v-model="actionParam.key"></span>
                <span style="max-width: calc(100% - 16px); overflow:hidden; text-overflow: ellipsis;" v-if="!actionParam.isEditing" class="color_enabled">{{ actionParam.key }}:</span>
                <button type="button"
                        v-bind:class="['align-self-end color_enabled relative px-2 inline-flex items-center rounded-md text-sm font-medium outline-none']"
                        v-on:click="actionParam.isEditing = !actionParam.isEditing">
                  <PencilIcon class="-ml-1 -mr-1 h-4 w-4 " aria-hidden="true"/>
                </button>
              </p>
            </li>
            <li class="flex flex-row flex-grow">
              <p class="mx-4 mb-2">
                <input class="w-full px-2" v-model="actionParam.value">
              </p>
            </li>
          </ul>
        </div>
        <li class="flex flex-row flex-grow mx-8 mb-4">
          <button @click="sendInstantAction()" class="mx-8 border w-full rounded-md py-1 button2">
            {{ $t("str_send_instant_action") }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <trigger-confirmation-popup v-if="showTriggerPopup" :selected-trigger="{trigger: {topic: '', value: '', type: ''}}"
            :customizable="true"
            :static-vehicle-id="vehicleId"
            @triggerConfirmed="(triggerConfirmed: boolean, trigger: any)=> {if (triggerConfirmed) sendTrigger(trigger); showTriggerPopup = false;}"/>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon, StopCircleIcon, MapPinIcon } from '@heroicons/vue/24/solid';
import {formatTimeString, getVehicleCheckColor, getVehicleIndicatorType} from "@/utils";
import {Switch} from '@headlessui/vue'
import TriggerConfirmationPopup from "@/components/customTriggers/TriggerConfirmationPopup.vue";
import Dropdown from "@/components/Dropdown.vue";
import {EdgeState, NodeState} from "../../dtos/VehicleState";</script>

<script lang="ts">
import { useConfig } from "@/main"
import { defineComponent } from 'vue'
import {publishInstantAction} from "@/network/MqttManager";
import {VehicleState} from "@/dtos/VehicleState";
import * as StorageManager from "@/datamanagers/StorageManager";
import { NodesManager } from "@/datamanagers/NodesManager";
import { liveViewEditorState } from '@/dtos/AppState';
import * as ApiManager from "../../network/ApiManager";
import HourglassIcon from '../HourglassIcon.vue';

export default defineComponent (
  {
  name: "VehicleShortView",
  data() {
    return {
      showTooltip: false,
      actions: new Array(),
      actionTypes: new Array(),
      expanded: false,
      instantAction: {
        action: {
          actionId: 0,
          actionDescription: "",
          actionType: "",
          blockingType: "",
        },
        actionParameters: new Array()
      },
      showTriggerPopup: false,
      enableVehicleLocalization: useConfig().config.configuration.enable_vehicle_localization || false,
      newVehiclePosition: liveViewEditorState.vehilceLocalization.position,
    }
  },
  props: {
    vehicleId: {
      type: String,
      default: "0"
    },
    vehicleState: {
      type: VehicleState,
      default: new VehicleState({})
    },
    nodesLookup: {
      type: Set,
      default: {}
    },
    initiallyExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getActions() {
      var actions = StorageManager.getActions().filter((action: any) => action.scope_instant == true)
      this.actions = actions;
      this.actionTypes = actions.map((a:any)=>JSON.parse(JSON.stringify(a)).actionType)
    },
    setInstantActionType(actionType: any) {
      const refAction = this.actions.find(value => value.actionType == actionType)
      this.instantAction.action = refAction
      this.instantAction.actionParameters = refAction.actionParameters
    },
    sendInstantAction() {
      const instantActionMsg = {
        actionId: this.instantAction.action.actionId,
        actionType: this.instantAction.action.actionType,
        actionDescription: this.instantAction.action.actionDescription,
        blockingType: this.instantAction.action.blockingType,
        actionParameters: this.instantAction.actionParameters
      }
      publishInstantAction(useConfig().config.configuration.mqtt,
          window.location.hostname,
          window.location.protocol,
          this.vehicleId,
          instantActionMsg)
    },
    saveLocalization() {
      const instantActionMsg = {
        actionDescription: "Resets (overrides) the pose of the AGV with the given paramaters.",
        actionId: 8,
        actionParameters: [
          {key: 'x', value: this.newVehiclePosition.x},
          {key: 'y', value: this.newVehiclePosition.y},
          {key: 'theta', value: this.newVehiclePosition.theta},
          {key: 'mapId', value: this.newVehiclePosition.mapId},
          {key: 'lastNodeId', value: this.vehicleState?.lastNodeId || ''},
        ],
        actionType: "initPosition",
        blockingType: "HARD",
      }
      publishInstantAction(useConfig().config.configuration.mqtt,
        window.location.hostname,
        window.location.protocol,
        this.vehicleId,
        instantActionMsg);
      this.$emit('activateVehicleLocalization', '');
    },
    toggleVehicleVisibility() {
      this.vehicleState.show_on_map = !this.vehicleState?.show_on_map
    },
    findVehicleOnMap() {
      if (!this.vehicleState.agvPosition)
        return;
      const position = {
        x: this.vehicleState.agvPosition.x,
        y: this.vehicleState.agvPosition.y,
        theta: this.vehicleState.agvPosition.theta,
        mapId: this.vehicleState.agvPosition.mapId
      }
      this.$emit('findVehicleOnMap', position)
    },
    sendTrigger(trigger: any) {
      ApiManager.sendCustomTrigger(trigger, () => {}, (res: any) => {
        console.log('successfully submitted trigger:', trigger);
      });
    },
  },
  created() {
    this.expanded = this.initiallyExpanded;
    this.getActions();
    window.addEventListener('mouseup', (e)=>{this.showTooltip = false}, false);
    window.addEventListener('touchend', (e)=>{this.showTooltip = false}, false);
  }
}
)
</script>

<style scoped>

</style>
