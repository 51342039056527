<template>
  <Listbox as="div" class="flex flex-column w-full" v-model="vehicleId">
    <ListboxLabel as="div" v-if="label.length>0" class="color_enabled">{{ label }}:</ListboxLabel>
    <div class="relative w-full ml-6 items-center">
      <ListboxButton as="div"
                     class="cursor-default rounded-sm border border-gray-300 bg-white pl-3 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
        <span v-if="showAutomatic" class="block truncate">{{
            String(vehicleId).length > 0 ?
            (vehicles.has(vehicleId) ? vehicles.get(vehicleId).name + '-' : '') + vehicleId : $t("str_automatic")
          }}</span>
        <span v-else class="block truncate">{{
            String(vehicleId).length > 0 ?
            (vehicles.has(vehicleId) ? vehicles.get(vehicleId).name + '-' : '') + vehicleId : $t("str_select_vehicle_prompt")
          }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ListboxOption as="template" v-if="showAutomatic" :value="String()" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    {{ $t("str_automatic") }}
                                  </span>

              <span v-if="selected"
                    :class="[active ? 'text-indigo-600' : 'text-indigo-600',
                                        'absolute flex inset-y-0 left-0 ml-2 items-center']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                                  </span>
            </li>
          </ListboxOption>
          <ListboxOption as="ul" class="list-style-type"
                         v-for="vehicle_id in sortedVehicles"
                         :key="vehicle_id"
                         :value="String(vehicle_id)" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">

                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                        <div :class="[!vehicles.get(vehicle_id).connected || selected
                           ? 'text-transparent' : getVehicleCheckColor(vehicles.get(vehicle_id)),
                            'h-2 w-2  absolute block left-3 top-4 rounded-full']"
                                                      aria-hidden="true"/>
                        {{ vehicles.get(vehicle_id).name }}-{{ vehicle_id }}:
                        {{ vehicles.get(vehicle_id).connected ? "online" : "offline" }}
                      </span>

              <span v-if="selected"
                    :class="[active ? 'text-indigo-600' : 'text-indigo-600',
                            'absolute flex inset-y-0 left-0 ml-2 items-center ']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                      </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {CheckIcon} from '@heroicons/vue/20/solid'
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from "@headlessui/vue";
import {getVehicleCheckColor} from "@/utils";</script>


<script lang="ts">
import { useConfig } from '@/main';
import {defineComponent} from "vue";
import {sort_vehicle} from "@/utils";
import {VehicleState} from "@/dtos/VehicleState";

export default defineComponent({
  name: "VehicleDropdown",
  data() {
    return {
      vehicleId: '',
      sortedVehicles: [] as unknown[],
    }
  },
  props: {
    vehicles:
      {
        type: Map,
        default: new Map()
      },
    label:
      {
        type: String,
        default: ''
      },
    showAutomatic:
      {
        type: Boolean,
        default: true
      },
  },
  watch: {
    vehicleId(newVal, oldVal) {
      this.$emit("vehicleSelected", newVal)
    },
    vehicles: {
      handler(newVal, oldVal) {
        if (Array.from(newVal.keys()).length != this.sortedVehicles.length) {
          const onlineVehicles = [] as string[];
          const offlineVehicles = [] as string[];
          newVal.forEach((vehicle: any, vehicleId: string) => {
            if (vehicle.connected) {
              onlineVehicles.push(vehicleId)
            } else {
              offlineVehicles.push(vehicleId)
            }
          })
          this.sortedVehicles = ([] as string[]).concat(
            onlineVehicles.sort((a: any, b:any) => sort_vehicle(a, b, this.vehicles, true)),
            offlineVehicles.sort((a: any, b:any) => sort_vehicle(a, b, this.vehicles))
          );
          // console.log(this.sortedVehicles)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.vehicleId = useConfig().localConfig.default_vehicle ? String(useConfig().localConfig.default_vehicle) : '';
  },
  methods: {
  }
})
</script>

<style scoped>

</style>
