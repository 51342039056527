<template>
  <div class="mx-auto w-full color_background_dark rounded-xl  transition-all hover:shadow hover:shadow-gray-500 hover:bg-gray-900 hover:bg-opacity-40 active:bg-gray-900 active:bg-opacity-70  active:shadow-none active:opacity-70">
    <main class="my-4 relative">
      <div class="absolute m-auto right-0">
        <div class="flex-row">
          <OptionsDropdown :options="[
            {title: $t('str_order_templates_edit'), handler: () => isEditingTrigger = true},
            {title: $t('str_order_templates_delete'), handler: () => confirmDeletion(() => deleteCustomTemplate(savedTrigger.title) )},
          ]"></OptionsDropdown>
        </div>
      </div>
      <div class="button2 flex-col px-4 py-4" @click="handleOrderClick">
        <p v-show="!isEditingTrigger" class="color_enabled text-xl text-left mb-2 pr-8 flex items-center">
          {{ savedTrigger.title }}
        </p>
        <p v-show="!isEditingTrigger && !!savedTrigger.description" class="color_enabled text-sm text-left mb-2">
          {{ savedTrigger.description }}
        </p>
        <p v-show="!isEditingTrigger && !!savedTrigger.category" class="color_enabled text-m text-left mb-2">
          {{ savedTrigger.category }}
        </p>
        <p v-show="!isEditingTrigger" class="color_enabled text-sm text-left mb-2 ml-2">
          <span class="-ml-2">Data: </span><br />
          topic: {{ savedTrigger?.trigger?.topic }} <br />
          value: {{ savedTrigger.trigger?.value }} <br />
          type: {{ savedTrigger?.trigger?.type }}
        </p>
        <form autocomplete="off" v-show="isEditingTrigger" @submit="(e:any)=>{e.preventDefault(); saveTrigger(form)}" @reset="(e:any)=>{e.preventDefault(); resetForm()}">
          <StyledInput class="text-gray-900" v-model="form.title" placeholder="Title" :floating="true" type="text" name="savedOrderTitle"/>
          <StyledInput class="text-gray-900" v-model="form.description" placeholder="Description" :floating="true" type="text" name="savedOrderTitle"/>
          <StyledInput class="text-gray-900" v-model="form.category" placeholder="Category" :floating="true" type="text" name="savedOrderTitle"/>
          Data: <br/>
          <p class="ml-2 text-sm">
            <StyledInput class="text-gray-900" v-model="form.trigger.topic" placeholder="Topic" :floating="true" type="text" name="savedOrderTitle"/>
            <StyledInput class="text-gray-900" v-model="form.trigger.value" placeholder="Value" :floating="true" type="text" name="savedOrderTitle"/>
            <StyledInput class="text-gray-900" v-model="form.trigger.type" placeholder="Type" :floating="true" type="text" name="savedOrderTitle"/>
          </p>
          <div class="flex -mt-2 mb-2">
            <button class="prevent-popup border w-1/2 mr-1 rounded-md py-1 button" :disabled="!checkTriggerValidity()">Submit</button>
            <button class="prevent-popup border w-1/2 ml-1 rounded-md py-1 button2" type="reset">Cancel</button>
          </div>
        </form>
      </div>
    </main>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon, StopIcon} from '@heroicons/vue/24/outline'
import OptionsDropdown from '@/components/OptionsDropdown.vue';
</script>

<script lang="ts">
import {defineComponent} from "vue";
import * as ApiManager from "@/network/ApiManager"
import { ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/vue/24/solid';
import * as AlertManager from "@/datamanagers/AlertsManager";
import {updateOrderTemplate} from "@/network/ApiManager";
import DialogPopup from '@/components/DialogPopup.vue';
import StyledInput from '../StyledInput.vue';
import { NodesManager } from '@/datamanagers/NodesManager';
import { liveViewEditorState } from '@/dtos/AppState';

export default defineComponent({
  name: "CustomTriggerItem",
  emits: ['triggerSelected', 'triggerEdit', 'triggerDelete'],
  data() {
    return {
      isEditingTrigger: !!this.editingTrigger,
      form: {
        title: this.savedTrigger?.title || '',
        description: this.savedTrigger?.description || '',
        category: this.savedTrigger?.category || '',
        trigger:
          {
            value: this.savedTrigger?.trigger?.value || '',
            topic: this.savedTrigger?.trigger?.topic || '',
            type: this.savedTrigger?.trigger?.type || ''
          },
      }
    }
  },
  props: {
    savedTrigger:
      {
        type: null,
        default: null
      },
    editingTrigger: 
      {
        default: false
      }
  },
  watch: {
    savedTrigger(newV, oldV) {
      console.log("Saved trigger", newV)
    },
  },
  computed: {},
  methods: {
    async confirmDeletion(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: this.$t("str_confirm_order_template_deletion_title"),
        message: this.$t("str_confirm_order_template_deletion_message"),
        okButton: this.$t("str_confirm_order_template_deletion_btn_delete"),
        cancelButton: this.$t("str_confirm_order_template_deletion_btn_cancel"),
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
    saveTrigger(payload: any) {
      if (this.checkTriggerValidity()) {
        this.$emit('triggerEdit', {...this.form, trigger_template_id: this.savedTrigger.trigger_template_id});
        this.isEditingTrigger = false;
      }
    },
    resetForm() {
      if (this.editingTrigger) {
        return this.$emit('triggerDelete', this.savedTrigger.trigger_template_id);
      }
      this.form = {
        title: this.savedTrigger.title || '',
        description: this.savedTrigger.description || '',
        category: this.savedTrigger.category || '',
        trigger:
          {
            value: this.savedTrigger.trigger.value || '',
            topic: this.savedTrigger.trigger.topic || '',
            type: this.savedTrigger.trigger.type || ''
          },
      };
      this.isEditingTrigger = false;
    },
    handleOrderClick(event: MouseEvent) {
      if (this.isEditingTrigger) return;
      if((event.target as HTMLElement).nodeName !== "INPUT" && !(event.target as HTMLElement).classList.contains('prevent-popup')) {
        this.$emit('triggerSelected', this.savedTrigger);
      }
    },
    checkTriggerValidity() {
      let isValid = true;
      if (
        !this.form.trigger.topic.length ||
        !this.form.trigger.type.length ||
        !this.form.trigger.value.length
      ) {
        isValid = false;
      }
      return isValid;
    },
    deleteCustomTemplate(triggerId: string) {
      this.$emit('triggerDelete', this.savedTrigger.trigger_template_id);
    }
  }
})


</script>

<style scoped>
</style>
