<template>
  <TransitionRoot appear :show="true" as="template">
    <Dialog as="div" @close="closeModal(false)" class="relative z-10">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
            <DialogPanel
                class="w-full max-w-md transform overflow-visible rounded-2xl color_background_light p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 color_enabled flex items-center">
                <ExclamationTriangleIcon v-if="!checkRouteValidity() && !savedRoute.criticalError" class="mt-1 mr-1 w-5 h-5 text-orange-500"/>
                <NoSymbolIcon v-if="!checkRouteValidity() && savedRoute.criticalError" class="mr-1 w-5 h-5 text-red-500"/>
                {{ savedRoute.title || `Route ${savedRoute.orderTemplateId}` }}
              </DialogTitle>
              <DialogDescription
                v-if="savedRoute.description?.length"
                as="h3"
                class="text-md leading-6 color_enabled">
                {{ savedRoute.description }}
              </DialogDescription>
              <div class="my-2">
                <ul v-if="!showFullRoute">
                  <li>
                    <div class="relative pb-2">
                      <span v-if="savedRoute.nodes.length !== 1"
                            class="absolute top-0 left-4 -ml-px h-full border-l-2 border-solid border-l-gray-400"
                            aria-hidden="true"/>
                      <div class="relative flex space-x-3">
                        <div>
                        <span
                            :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                            <div v-if="savedRoute?.nodes[0]?.node !== null">
                              <component v-if="savedRoute.nodes.length !== 1" :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                              <component v-if="savedRoute.nodes.length === 1"
                                        :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                        aria-hidden="true"/>
                            </div>
                            <ExclamationTriangleIcon v-if="savedRoute?.nodes[0]?.node === null" class="w-5 h-5 text-orange-500"/>
                        </span>
                        </div>
                        <div class="flex flex-col min-w-0 space-x-4">
                          <div>
                            <p :class="[savedRoute?.nodes[0]?.node === null ? 'text-gray-500' : 'color_enabled', 'text-xl text-left flex items-center']">
                              {{ savedRoute?.nodes[0]?.nodeId }}: {{ NodesManager.nodes.get(savedRoute?.nodes[0]?.nodeId)?.nodeDescription }}
                            </p>
                          </div>
                          <ul class="mt-2 list-disc" v-if="savedRoute?.nodes?.length < 2 && (savedRoute?.nodes[0].actions.length || NodesManager.nodes.get(savedRoute?.nodes[0].nodeId)?.actions?.length)">
                            <li v-for="item in !savedRoute?.nodes[0].defaultActions? savedRoute?.nodes[0].actions : NodesManager.nodes.get(savedRoute?.nodes[0].nodeId)?.actions"
                                class="color_disabled">
                              <p class="color_disabled uppercase text-sm text-left">
                                {{ item.action.actionType }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li v-if="savedRoute?.nodes?.length > 2">
                    <div class="relative mb-2">
                      <span class="absolute top-0 left-4 -ml-px h-full border-l-2 border-dotted border-l-gray-400"
                            aria-hidden="true"/>
                      <div class="relative align-center flex min-w-0 ml-8">
                        <p class="text-gray-400 text-xl text-left">
                          {{ `... ${savedRoute?.nodes?.length - 2} ${$t('str_more')}` }}
                        </p>
                        <ChevronDownIcon class="h-7 w-7 text-gray-400 cursor-pointer" v-on:click="() => showFullRoute = !showFullRoute" aria-hidden="true"/>
                      </div>
                    </div>
                  </li>
                  <li v-if="savedRoute.nodes.length !== 1">
                    <div class="relative pb-2">
                      <span class="absolute bottom-full left-4 -ml-px h-[20%] border-l-2 border-solid border-l-gray-400"
                            aria-hidden="true"/>
                      <div class="relative flex space-x-3">
                        <div>
                        <span :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                          <div v-if="savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node !== null">
                            <component :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                      aria-hidden="true"/>
                          </div>
                          <ExclamationTriangleIcon v-if="savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node === null" class="w-5 h-5 text-orange-500"/>
                        </span>
                        </div>
                        <div class="flex flex-col min-w-0 space-x-4">
                          <div>
                            <p :class="[savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node === null ? 'text-gray-500' : 'color_enabled', 'text-xl text-left flex items-center']">
                              {{ savedRoute?.nodes[savedRoute?.nodes.length - 1]?.nodeId }}: {{ NodesManager.nodes.get(savedRoute?.nodes[savedRoute?.nodes.length - 1]?.nodeId)?.nodeDescription }}
                            </p>
                          </div>
                          <ul class="mt-2 list-disc" v-if="savedRoute?.nodes?.length < 2 && (savedRoute?.nodes[savedRoute?.nodes.length - 1].actions.length || NodesManager.nodes.get(savedRoute?.nodes[savedRoute?.nodes.length - 1].nodeId)?.actions?.length)">
                            <li v-for="item in !savedRoute?.nodes[savedRoute?.nodes.length - 1].defaultActions ? savedRoute?.nodes[savedRoute?.nodes.length - 1].actions : NodesManager.nodes.get(savedRoute?.nodes[savedRoute?.nodes.length - 1].nodeId)?.actions"
                                class="color_disabled">
                              <p class="color_disabled uppercase text-sm text-left">
                                {{ item.action.actionType }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul v-if="showFullRoute">
                  <li v-for="(node, nodeIdx) in savedRoute?.nodes ?? []">
                    <div class="relative pb-2">
                    <span v-if="nodeIdx !== savedRoute?.nodes?.length - 1"
                          class="absolute top-4 left-4 -ml-px h-full border-l-2 border-solid border-l-gray-400"
                          aria-hidden="true"/>
                      <div class="relative flex space-x-3">
                        <div>
                    <span :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                      <div v-if="node.node !== null">
                        <component v-if="savedRoute?.nodes?.length===1 || nodeIdx === savedRoute?.nodes?.length - 1"
                                  :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                  aria-hidden="true"/>
                        <component v-else :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                      </div>
                      <ExclamationTriangleIcon v-if="node.node === null" class="w-5 h-5 text-orange-500"/>
                    </span>
                      </div>
                        <div class="flex min-w-0 space-x-4">
                          <div>
                            <p :class="[node.node === null ? 'text-gray-500' : 'color_enabled', 'text-xl text-left flex items-center']">
                              {{ node.nodeId }}: {{ NodesManager.nodes.get(node.nodeId)?.nodeDescription }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <ul class="pl-16 mt-2 list-disc">
                        <li v-for="item in !node.defaultActions? node.actions : NodesManager.nodes.get(node.nodeId)?.actions"
                            class="color_disabled">
                          <p class="color_disabled uppercase text-sm text-left">
                            {{ item.action.actionType }}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 color_enabled mb-2">
                  {{ $t('str_confirm_order_from_template') }}
                </DialogTitle>
                <div v-if="useConfig().localConfig.hard_vehicle_restriction" class="color_enabled mb-2">{{ $t('str_vehicle') }}: <span class="ml-6">{{ `${vehicles.get(vehicleId).name}-${vehicleId}` }}</span></div>
                <VehicleDropdown v-else class="mb-2" :vehicles="vehicles" :label="$t('str_vehicle')"
                                @vehicleSelected="setVehicleId"/>
                <li class="flex flex-row flex-grow mb-2">
                  <p class="color_enabled items-center mr-6">{{ $t("str_order_request_priority") }}: </p>
                  <Dropdown class="w-full" :elem-list="priorityValues"
                            :prompt="priorityValues[orderRequestPriority]"
                            :default-selection-id="priorityValues[orderRequestPriority]"
                            @selectedEl="(el:string)=>orderRequestPriority=priorityValues.indexOf(el)"/>
                </li>

                <div class="mt-4 flex justify-end">
                  <button
                      type="button"
                      class="flex justify-center rounded-md border px-4 py-2 text-sm font-medium button "
                      @click="closeModal(true)"
                      :disabled="!checkRouteValidity()"
                  >
                    {{ $t('str_submit_order') }}
                  </button>

                  <button
                      type="button"
                      class="flex justify-center rounded-md border mx-4 px-4 py-2 text-sm font-medium button2 "
                      @click="closeModal(false)"
                  >
                    {{ $t('str_cancel') }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon, ChevronDownIcon} from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/vue/24/solid';
import {Dialog, DialogDescription, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,} from '@headlessui/vue'
import VehicleDropdown from "@/components/VehicleDropdown.vue";
import Dropdown from "@/components/Dropdown.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import {getPriorityStrings} from "@/utils";
import { useConfig } from '@/main';
import { NodesManager } from '@/datamanagers/NodesManager';

export default defineComponent({
  name: "OrderConfirmationPopup",
  data() {
    return {
      vehicleId: String(useConfig().localConfig.default_vehicle || ""),
      orderRequestPriority: 1, //high priority
      priorityValues: getPriorityStrings(this),
      showFullRoute: false,
    }
  },
  props: {
    savedRoute:
      {
        //  {nodes: [{nodeId: String}]}
        type: null,
        default: null
      },
    vehicles:
      {
        type: Map,
        default: []
      },
  },
  watch: {
    savedRoute(newV, oldV) {
      console.log("Saved route", newV)
    },
  },
  computed: {},
  mounted() {
    if (!this.checkRouteValidity())
      this.showFullRoute = true;
  },
  methods: {
    closeModal(submitOrder: boolean) {
      this.$emit('orderConfirmed', submitOrder, {
        nodes: this.savedRoute?.nodes ?? [], vehicleId: this.vehicleId, priority: this.orderRequestPriority
      })
    },
    saveRoute() {

    },
    checkNodeValidity(node: any) {
      return NodesManager.nodes.has(node.nodeId);
    },
    updateNode(node: any) {
      console.log('before', node.node);
      if (NodesManager.nodes.has(Number(node.nodeId))) {
        const nodeData = NodesManager.nodes.get(Number(node.nodeId));
        console.log(nodeData)
        node.node = {
          nodeDescription: nodeData?.nodeDescription,
          nodeId: node.nodeId
        }
      } else {
        node.node = null;
      }
      console.log('after', node.node);
    },
    setVehicleId(selectedId: string) {
      this.vehicleId = selectedId
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    },
    checkRouteValidity() {
      let isValid = true;
      this.savedRoute.nodes.forEach((node: any) => {
        if (node.node === null)
          isValid = false;
      });
      return isValid;
    },
  }
})


</script>

<style scoped>
</style>
