<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto  p-4 h-full ">
      <div class="flex flex-col fullscreen justify-between">
        <div class="flex w-auto flex-col pb-16">
          <div class="w-full h-full ">
            <h2 class="color_disabled uppercase text-sm mb-2">{{ $t('str_usermanagement_account_settings') }}</h2>
            <div class="flex space-x-4">
              <div class="flex-1 color_enabled mx-auto w-full color_background_dark rounded-xl p-4">
                <h3 class="text-md uppercase text-sm pb-4">{{ $t('str_usermanagement_current_user') }}:</h3>
                <div class="flex justify-between p-2 text-sm">
                  <div>{{ $t('str_usermanagement_full_name') }}:</div>
                  <div>{{ currentUser.name || '-' }}</div>
                </div>
                <div class="flex justify-between p-2 text-sm">
                  <div>{{ $t('str_usermanagement_username') }}:</div>
                  <div>{{ currentUser.username || '-' }}</div>
                </div>
                <div class="flex justify-between p-2 text-sm">
                  <div>{{ $t('str_usermanagement_email') }}:</div>
                  <div>{{ currentUser.email || '-' }}</div>
                </div>
                <div class="flex justify-between p-2 text-sm">
                  <div>{{ $t('str_usermanagement_role') }}:</div>
                  <div>{{ currentUser.role.name || '-' }}</div>
                </div>
                <div class="flex justify-end mt-5">
                  <button type="button" v-on:click="signOut"
                    class="deletebutton min-w-[200px] border rounded-lg p-1 place-self-end">{{ $t('str_usermanagement_signout')
                    }}</button>
                </div>
              </div>
              <div class="flex-1 mx-auto w-full color_background_dark rounded-xl p-4">
                <h3 class="color_enabled uppercase text-sm pb-4">{{ $t('str_usermanagement_change_password') }}:</h3>
                <styled-input :placeholder="$t('str_usermanagement_old_password')" v-model="oldPass" floating
                  type="password" />
                <styled-input :placeholder="$t('str_usermanagement_new_password')" v-model="newPass" floating
                  type="password" />
                <styled-input :placeholder="$t('str_usermanagement_repeat_new_password')" v-model="confirmNewPass"
                  floating type="password" />
                <div class="flex justify-end">
                  <button type="button" v-on:click="changeUserPassword(oldPass, newPass, confirmNewPass)"
                    class="button2 min-w-[200px] border rounded-lg p-1 place-self-end -mt-2" :disabled="newPass !== confirmNewPass">{{
                      $t('str_usermanagement_submit_new_password') }}</button>
                </div>
              </div>
            </div>

            <h2 v-if="currentUserState.role.role_level === 0" class="color_disabled uppercase text-sm mb-2 mt-4">
              {{ $t('str_usermanagement_user_management') }}</h2>
            <div v-if="currentUserState.role.role_level === 0"
              class="flex-1 mx-auto w-full color_background_dark rounded-xl p-4 mt-2 relative">
              <h4 class="color_enabled uppercase text-sm pb-4">{{ $t('str_usermanagement_users_list') }}:</h4>
              <button class="absolute right-4 top-4 flex" v-on:click="getAllUsers" :disabled="isRefreshingUserList">
                <CheckIcon v-if="showRefreshUsersListComplete && !isRefreshingUserList"
                  :class='{ "h-6 w-6 text-green-600 font-thin": true }' />
                <span v-if="isRefreshingUserList" class="color_enabled mr-2">{{ $t('str_usermanagement_loading') }}...
                </span>
                <ArrowPathIcon :class='{ "animate-spin": isRefreshingUserList, "h-6 w-6 color_accent font-thin": true }'
                  aria-hidden="true" style="stroke-width: 0.8" />
              </button>
              <ul>
                <li class="flex color_enabled border-b-[1px] border_color_offwhite p-2">
                  <div class="w-3/12 text-sm">{{ $t('str_usermanagement_full_name') }}:</div>
                  <div class="w-3/12 text-sm">{{ $t('str_usermanagement_username') }}:</div>
                  <div class="w-3/12 text-sm">{{ $t('str_usermanagement_email') }}:</div>
                  <div class="w-2/12 text-sm">{{ $t('str_usermanagement_role') }}:</div>
                  <div class="w-1/12 text-sm overflow-hidden">{{ $t('str_usermanagement_actions') }}:</div>
                </li>
                <li v-for="user in users" :key="user.username" class="border-b-[1px] border_color_offwhite p-2">
                  <div v-if="user.username !== editedUser.oldUsername" class="flex color_enabled">
                    <div class="w-3/12 flex items-center text-sm">{{ user.name || '-' }}</div>
                    <div class="w-3/12 flex items-center text-sm">{{ user.username || '-' }}</div>
                    <div class="w-3/12 flex items-center text-sm">{{ user.email || '-' }}</div>
                    <div class="w-2/12 flex items-center text-sm">{{ rolesMap.get(user.role_id)?.name || user.role_id }}</div>
                    <div class="w-1/12 flex items-center text-sm justify-end">
                      <OptionsDropdown :options="[
                        { title: $t('str_usermanagement_edit'), handler: () => editUser(user) },
                        { title: $t('str_usermanagement_delete'), handler: () => deleteUser(user.username) },
                      ]"></OptionsDropdown>
                    </div>
                  </div>
                  <div v-if="user.username === editedUser.oldUsername" class="flex space-x-4">
                    <div class="w-3/12">
                      <styled-input :placeholder="$t('str_usermanagement_full_name')" v-model="editedUser.name" floating
                        type="text" />
                    </div>
                    <div class="w-3/12">
                      <styled-input :placeholder="$t('str_usermanagement_username')" v-model="editedUser.username"
                        floating type="text" />
                      <button v-if="!editedUser.enablePasswordChange" v-on:click="() => showEditingUserPasswordField()"
                        class="border rounded-md m-1 p-1 button">
                        {{ $t('str_usermanagement_change_password') }}
                      </button>
                      <styled-input v-if="editedUser.enablePasswordChange"
                        :placeholder="$t('str_usermanagement_new_password')" v-model="editedUser.password" floating
                        type="text" />
                    </div>
                    <div class="w-3/12">
                      <styled-input :placeholder="$t('str_usermanagement_email')" v-model="editedUser.email" floating
                        type="email" />
                    </div>
                    <div class="w-2/12">
                      <label class="color_enabled text-sm">{{ $t('str_usermanagement_role') }}</label>
                      <Dropdown class="mt-1"
                        :selected-item="rolesMap.get(editedUser.role_id).name || null"
                        :is-filterable="false" :elem-list="Array.from(rolesMap).map(elem => elem[1].name)"
                        :prompt="rolesMap.get(editedUser.role_id)?.name || null"
                        :default-selection-id="rolesMap.get(editedUser.role_id)?.name || null"
                        @selectedEl="newRole => editedUser.role_id = getRoleIdByName(newRole)" />
                    </div>
                    <div class="w-1/12 flex items-center justify-center">
                      <button v-on:click="() => editedUser = {}" class="border rounded-md m-1 p-1 button">
                        <XMarkIcon :class='{ "h-6 w-6 font-thin": true }' aria-hidden="true" style="stroke-width: 0.8" />
                      </button>
                      <button v-on:click="() => updateUser(user.username)" class="border rounded-md m-1 p-1 button2">
                        <CheckIcon :class='{ "h-6 w-6 font-thin": true }' aria-hidden="true" style="stroke-width: 0.8" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <button v-if="!showCreateNewUser" class="button2 border rounded-lg p-1 min-w-[200px] mt-4"
                v-on:click="showCreateNewUser = true">{{ $t('str_usermanagement_create_new_user') }}</button>
              <div class="w-full mt-4" v-if="showCreateNewUser">
                <div class="flex justify-between content-middle">
                  <h3 class="color_enabled text-md font-semibold mt-4">{{ $t('str_usermanagement_create_new_user') }}:
                  </h3>
                </div>
                <li class="flex space-x-4">
                  <div class="w-1/4">
                    <styled-input :placeholder="$t('str_usermanagement_full_name')" v-model="newUserForm.name" floating
                      type="text" />
                  </div>
                  <div class="w-1/4">
                    <styled-input :placeholder="$t('str_usermanagement_username')" v-model="newUserForm.username" floating
                      type="text" />
                  </div>
                  <div class="w-1/4">
                    <styled-input :placeholder="$t('str_usermanagement_email')" v-model="newUserForm.email" floating
                      type="email" />
                  </div>
                  <div class="w-1/4">
                    <styled-input :placeholder="$t('str_usermanagement_password')" v-model="newUserForm.password" floating
                      type="password" />
                  </div>
                </li>
                <div class="flex justify-end">
                  <button class="deletebutton border rounded-lg p-1 mr-4 min-w-[200px]"
                    v-on:click="() => { showCreateNewUser = false; newUserForm = {} }">{{ $t('str_usermanagement_cancel')
                    }}</button>
                  <button class="button2 border rounded-lg p-1 min-w-[200px]" v-on:click="createUser">{{
                    $t('str_usermanagement_submit') }}</button>
                </div>
              </div>
            </div>

            <div v-if="currentUserState.role.role_level === 0"
              class="flex-1 mx-auto w-full color_background_dark rounded-xl p-4 mt-4 relative">
              <h4 class="color_enabled uppercase text-sm pb-4">{{ $t('str_usermanagement_roles_list') }}:</h4>
              <button class="absolute right-4 top-4 flex" v-on:click="getAllRoles" :disabled="isRefreshingRolesList">
                <CheckIcon v-if="showRefreshRolesListComplete && !isRefreshingRolesList"
                  :class='{ "h-6 w-6 text-green-600 font-thin": true }' />
                <span v-if="isRefreshingRolesList" class="color_enabled mr-2">{{ $t('str_usermanagement_loading') }}...
                </span>
                <ArrowPathIcon :class='{ "animate-spin": isRefreshingRolesList, "h-6 w-6 color_accent font-thin": true }'
                  aria-hidden="true" style="stroke-width: 0.8" />
              </button>
              <ul>
                <li class="flex color_enabled border-b-[1px] border_color_offwhite p-2 text-sm">
                  <div class="w-3/12">{{ $t('str_usermanagement_role_name') }}:</div>
                  <div class="w-2/12">{{ $t('str_usermanagement_access_level') }}:</div>
                  <div class="w-6/12">{{ $t('str_usermanagement_description') }}:</div>
                  <div class="w-1/12 overflow-hidden">{{ $t('str_usermanagement_actions') }}:</div>
                </li>
                <li
                  v-for="role in Array.from(rolesMap).sort((a, b) => Number(a[1].role_level) > Number(b[1].role_level) ? 1 : (Number(a[1].role_level) < Number(b[1].role_level) ? -1 : 0))"
                  :key="(role[1].id)" class="border-b-[1px] border_color_offwhite p-2 text-sm">
                  <div v-if="role[1].id !== editedRole.id" class="flex color_enabled">
                    <div class="w-3/12 flex items-center">{{ role[1].name }}</div>
                    <div class="w-2/12 flex items-center">{{ String(role[1].role_level) }}</div>
                    <div class="w-6/12 flex items-center">{{ role[1].description }}</div>
                    <div class="w-1/12 flex items-center justify-end">
                      <OptionsDropdown :options="[
                        { title: $t('str_usermanagement_edit'), handler: () => editRole(role[1]) },
                        { title: $t('str_usermanagement_delete'), handler: () => deleteRole(role[1].id, fallbackRole) },
                      ]"></OptionsDropdown>
                    </div>
                  </div>
                  <div v-if="role[1].id === editedRole.id" class="flex space-x-4">
                    <div class="w-3/12">
                      <styled-input :placeholder="$t('str_usermanagement_role_name')" v-model="editedRole.name" floating
                        type="text" />
                    </div>
                    <div class="w-2/12">
                      <label class="color_enabled text-sm">{{ $t('str_usermanagement_access_level') }}</label>
                      <Dropdown class="mt-1" :selected-item="editedRole.role_level" :is-filterable="false"
                        :elem-list="[0, 1, 2]" :prompt="editedRole.role_level"
                        @selectedEl="newLevel => editedRole.role_level = newLevel" />
                    </div>
                    <div class="w-6/12">
                      <styled-input :placeholder="$t('str_usermanagement_description')" v-model="editedRole.description"
                        floating type="email" />
                    </div>
                    <div class="w-1/12 flex items-center justify-center">
                      <button v-on:click="() => editedRole = {}" class="border rounded-md m-1 p-1 button">
                        <XMarkIcon :class='{ "h-6 w-6 font-thin": true }' aria-hidden="true" style="stroke-width: 0.8" />
                      </button>
                      <button v-on:click="() => updateRole(Number(role[1].id))" class="border rounded-md m-1 p-1 button2">
                        <CheckIcon :class='{ "h-6 w-6 font-thin": true }' aria-hidden="true" style="stroke-width: 0.8" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <button v-if="!showCreateNewRole" class="button2 border rounded-lg p-1 min-w-[200px] mt-4"
                v-on:click="showCreateNewRole = true">{{ $t('str_usermanagement_create_new_role') }}</button>
              <div class="w-full mt-4" v-if="showCreateNewRole">
                <div class="flex justify-between content-middle">
                  <h3 class="color_enabled text-md font-semibold mt-4">{{ $t('str_usermanagement_create_new_role') }}:
                  </h3>
                </div>
                <li class="flex space-x-4">
                  <div class="w-3/12">
                    <styled-input :placeholder="$t('str_usermanagement_role_name')" v-model="newRoleForm.name" floating
                      type="text" />
                  </div>
                  <div class="w-2/12">
                    <label class="block color_enabled text-sm">{{ $t('str_usermanagement_access_level') }}</label>
                    <Dropdown class="mt-2" :selected-item="newRoleForm?.role_level || 0" :is-filterable="false"
                      :elem-list="[0, 1, 2]" :prompt="newRoleForm.role_level"
                      @selectedEl="newLevel => newRoleForm.role_level = newLevel" />
                  </div>
                  <div class="w-7/12">
                    <styled-input :placeholder="$t('str_usermanagement_description')" v-model="newRoleForm.description"
                      floating type="text" />
                  </div>
                </li>
                <div class="flex justify-end">
                  <button class="deletebutton border rounded-lg p-1 mr-4 min-w-[200px]"
                    v-on:click="() => { showCreateNewRole = false; newRoleForm = {} }">{{ $t('str_usermanagement_cancel')
                    }}</button>
                  <button class="button2 border rounded-lg p-1 min-w-[200px]"
                    v-on:click="() => createRole(newRoleForm)">{{ $t('str_usermanagement_submit') }}</button>
                </div>
              </div>
              <!-- <button class="button2 border rounded-lg p-1 min-w-[200px]" v-on:click="_helperCreateGenericRoles">Generate generic Roles</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">
import StyledInput from '@/components/StyledInput.vue';
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'
</script>

<script lang="ts">
import { defineComponent } from 'vue'
import DialogPopup from '@/components/DialogPopup.vue';
import OptionsDropdown from '@/components/OptionsDropdown.vue';
import Dropdown from '@/components/Dropdown.vue';
import * as ApiManager from "../network/ApiManager";
import * as StorageManager from "../datamanagers/StorageManager";
import { currentUserState } from "../datamanagers/ReactiveStates";
import * as AlertManager from '@/datamanagers/AlertsManager';

export default defineComponent({
  name: "UserManagement",
  data() {
    return {
      currentUser: {
        username: '',
        name: '',
        email: '',
        role_id: null as number | null,
        role: {
          description: '',
          id: 1,
          name: '',
          permissions: [] as any[],
          role_level: 2
        }
      },
      oldPass: '',
      newPass: '',
      confirmNewPass: '',
      editedUser: {} as any,
      showCreateNewUser: false,
      newUserForm: {
        username: '',
        name: '',
        email: '',
        password: '',
        role_id: 1
      } as any,
      isRefreshingUserList: false,
      showRefreshUsersListComplete: false,
      isRefreshingRolesList: false,
      showRefreshRolesListComplete: false,
      rolesMap: new Map(),
      editedRole: {} as any,
      showCreateNewRole: false,
      newRoleForm: {
        name: '',
        role_level: 0,
        description: '',
        permissions: [] as any[]
      } as any,
      fallbackRole: null as number | string | null,
      users: [] as {
        username: string,
        name: string,
        email: string,
        role_id: number
      }[],
    };
  },
  watch: {
    showRefreshUsersListComplete(newVal) {
      if (newVal) setTimeout(() => this.showRefreshUsersListComplete = false, 1000);
    },
    showRefreshRolesListComplete(newVal) {
      if (newVal) setTimeout(() => this.showRefreshRolesListComplete = false, 1000);
    },
  },
  components: {

  },
  methods: {
    async signOut() {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: this.$t('str_usermanagement_signout'),
        message: this.$t('str_usermanagement_confirm_signout'),
        okButton: this.$t('str_usermanagement_signout'),
        cancelButton: this.$t('str_usermanagement_cancel'),
      });
      if (confirmed) {
        StorageManager.removeUser();
        StorageManager.removeJwt();
        this.currentUser = {
          username: '',
          name: '',
          email: '',
          role_id: null,
          role: {
            description: '',
            id: 1,
            name: '',
            permissions: [] as any[],
            role_level: 2
          }
        };
        this.$router.go(0);
        console.log('Signed out');
      } else {
        console.log('Cancelled');
      }
    },
    changeUserPassword(oldPass: string, newPass: string, confirmNewPass: string) {
      if (newPass !== confirmNewPass) return;
      ApiManager.changeOwnPassword({ password: oldPass, new_password: newPass }, () => { }, () => {
        console.log(`password changed`);
      });
    },
    getAllUsers() {
      if (this.currentUser.role.role_level !== 0) return;
      this.isRefreshingUserList = true;
      ApiManager.getAllUsers(() => {
        console.log('There was an error getting users list');
        this.isRefreshingUserList = false;
      }, (res: any) => {
        this.users = res.data.users;
        this.isRefreshingUserList = false;
        this.showRefreshUsersListComplete = true;
      })
    },
    createUser() {
      // TODO - validation of the form fields

      // API call to create user
      ApiManager.createUser(this.newUserForm, () => { }, (res: any) => {
        console.log('created new user - ', res);
        this.users.push({ ...this.newUserForm });
        this.showCreateNewUser = false;
        this.newUserForm = {
          username: '',
          name: '',
          email: '',
          password: '',
          role_id: 1
        };
      });
    },
    editUser(user: any) {
      this.editedUser = { ...user };
      this.editedUser.oldUsername = this.editedUser.username;
    },
    showEditingUserPasswordField() {
      this.editedUser.password = '';
      this.editedUser.enablePasswordChange = true;
    },
    updateUser(username: any) {
      delete this.editedUser.enablePasswordChange;
      // API call to update user
      ApiManager.editUser(this.editedUser, () => { }, (res: any) => {
        this.users[this.users.map(user => user.username).indexOf(username)] = { ...this.editedUser };
        this.editedUser = {};
      })
    },
    async deleteUser(username: string) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: this.$t('str_usermanagement_delete_user'),
        message: this.$t('str_usermanagement_delete_user_confirmation'),
        okButton: this.$t('str_usermanagement_delete'),
        cancelButton: this.$t('str_usermanagement_cancel'),
      });
      if (confirmed) {
        ApiManager.deleteUser(username, () => { }, () => {
          console.log('deleted user', username);
          this.users = this.users.filter(user => user.username !== username);
        });
      } else {
        console.log('User deletion cancelled');
      }
    },
    getAllRoles() {
      if (this.currentUser.role.role_level !== 0) return;
      this.isRefreshingRolesList = true;
      ApiManager.getAllRoles(() => this.isRefreshingRolesList = false, (res: any) => {
        this.isRefreshingRolesList = false;
        this.showRefreshRolesListComplete = true;
        if (!res || !res.data.roles?.length) return;
        res.data.roles.map((role: any) => {
          this.rolesMap.set(role.id, role);
        });
      }, () => { })
    },
    createRole(newRole: any) {
      // API call to create new role
      ApiManager.createRole(newRole, () => { }, (res: any) => this.getAllRoles());
    },
    editRole(role: any) {
      this.editedRole = { ...role };
      this.editedRole.role_level = String(this.editedRole.role_level);
    },
    updateRole(roleId: number) {
      const payload = JSON.parse(JSON.stringify(this.editedRole));
      ApiManager.editRole(payload, () => { }, (res: any) => {
        this.rolesMap.set(roleId, JSON.parse(JSON.stringify(this.editedRole)));
        this.editedRole = {};
      });
    },
    async deleteRole(roleId: string | number, fallbackId: string | number | null) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: this.$t('str_usermanagement_delete_role'),
        message: this.$t('str_usermanagement_delete_role_confirmation'),
        okButton: this.$t('str_usermanagement_delete'),
        cancelButton: this.$t('str_usermanagement_cancel'),
      });
      if (confirmed) {
        // TODO - fallback role assignment
        if (!fallbackId) fallbackId = 1;
        ApiManager.deleteRole({ roleId, fallbackId }, () => { }, () => {
          console.log('deleted role', this.rolesMap.get(roleId).name);
          // refresh all users list to get reassigned with fallback roles
          this.getAllUsers();
          this.rolesMap.delete(roleId);
        });
      } else {
        console.log('Role deletion cancelled');
      }
    },
    getRoleIdByName(roleName: string) {
      let roleKey = [...this.rolesMap.entries()]
        .filter(({ 1: value }) => value.name.toLowerCase() === roleName.toLowerCase())
        .map(([k]) => k);
      if (roleKey.length) {
        return roleKey[0];
      }
    },
    _helperCreateGenericRoles() {
      ApiManager.getAllRoles(() => { console.log('no results') }, (res: any) => console.log('all roles', res), () => {
        // If no roles stored - create admin
        console.log('No roles exist. Creating generic roles');
        ['Admin', 'Manager', 'Worker'].forEach((role_name, index) => {
          this.rolesMap.set(index + 1, role_name);
          const newRoleObj = {
            name: role_name,
            role_level: String(index),
            permissions: [
              role_name
            ],
            description: "No description for " + role_name
          };
          this.createRole(newRoleObj);
        });
      });
    },
  },
  beforeMount() {
    const storedUser = StorageManager.getUser();
    if (!!storedUser?.username) this.currentUser = storedUser;

    // API call to get actual roles list
    this.getAllRoles();
    // API call to get users list
    this.getAllUsers();
  },
  mounted() {

  },
  created() {

  }
})
</script>

<style></style>
