<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto  p-4 h-full ">
      <div class="flex sm:flex-wrap">
        <div>
          <button type="button" @click="() => showFiltersForm = !showFiltersForm"
                  class="relative inline-flex items-center rounded-md border px-4 py-2 mx-1 text-sm font-medium color_enabled border_color_offwhite">
            <FunnelIcon class="-mx-1 h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
        <div v-if="showFiltersForm" class="flex flex-col w-[calc(100%-5rem)]" style="min-width: 300px;">
          <div class="color_enabled text-lg text-medium w-full h-9 mb-2 ml-2 items-center flex">{{ $t("str_filter_by") }}:</div>
          <div class="flex flex-col w-1/3 px-2">
            <div class="flex flex-row items-center justify-between mb-2">
              <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">{{ $t('str_matching_text') }}:</span>
              <div class="max-w-md mb-0">
                <styled-input name="text" class="mt-0 mb-0" type="text" v-model="filters.text"/>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40">{{ $t('str_category') }}:</span>
              <Dropdown
                class="max-w-md mb-0"
                style="margin-bottom:0;"
                :elem-list="(Array.from(availableCategories))"
                :prompt="'Select Category'"
                name="vehicle_id"
                v-model="filters.categories"
                @selectedEl="(elem) => filters.categories = elem"
                />
            </div>
            <div class="flex mt-2">
              <button class="mx-1 border w-full rounded-md py-2 text-sm button" @click="clearFilters">{{ $t("str_clear_filters") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col fullscreen justify-between">
        <div class="flex w-auto flex-col pb-16">
          <div class="w-full h-full ">
            <ul role="list"
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              <li v-for="trigger in filteredSavedTriggers" :key="trigger.trigger_template_id" class="relative">
                <CustomTriggerItem :saved-trigger="trigger" @triggerSelected="confirmTrigger" @triggerEdit="editTrigger" @trigger-delete="deleteTrigger" :editingTrigger="trigger.trigger_template_id === 0"/>
              </li>
              <li v-if="!savedTriggers.has(0)">
                <div class="mx-auto w-full color_background_dark rounded-xl mt-4 transition-all hover:shadow hover:shadow-gray-500 hover:bg-gray-900 hover:bg-opacity-40 active:bg-gray-900 active:bg-opacity-70  active:shadow-none active:opacity-70">
                  <div class="button2 flex color_enabled first-letter:flex-col px-4 py-4" @click="createNewTrigger">
                    <PlusCircleIcon class="w-12 h-12 my-6 mx-auto" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <trigger-confirmation-popup v-if="selectedTrigger != null" :selected-trigger="selectedTrigger"
                                @triggerConfirmed="(triggerConfirmed: boolean, trigger: any)=> {if (triggerConfirmed) submitTrigger(trigger);
                                                                    selectedTrigger=null;}"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {FunnelIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
</script>


<script lang="ts">
import {defineComponent} from 'vue'
import * as ApiManager from "../network/ApiManager";
import * as AlertManager from '@/datamanagers/AlertsManager'
import Dropdown from '@/components/Dropdown.vue';
import StyledInput from "@/components/StyledInput.vue";
import CustomTriggerItem from "@/components/customTriggers/CustomTriggerItem.vue";
import TriggerConfirmationPopup from "@/components/customTriggers/TriggerConfirmationPopup.vue";

export default defineComponent({
  name: "CustomTriggers",
  data() {
    return {
      selectedTrigger: null as unknown as any,
      savedTriggers: new Map<number, {
          title: string,
          description: string,
          category: string,
          trigger:
          {
            value: string,
            topic: string,
            type: string
          },
          trigger_template_id?: number
        }>(),
      filteredSavedTriggers: [] as {
          title: string,
          description: string,
          category: string,
          trigger:
          {
            value: string,
            topic: string,
            type: string
          },
          trigger_template_id?: number,
        }[],
      availableCategories: new Set() as Set<String>,
      showFiltersForm: false,
      filters: {
        text: '',
        categories: '',
      }
    };
  },
  components: {
    CustomTriggerItem
  },
  watch: {
    filters:{
      handler(newVal, oldVal) {
        this.filterSavedTriggers();
      },
      deep: true
    }
  },
  methods: {
    getAllCustomTriggers() {
      ApiManager.getAllTriggers(
        () => {},
        (data: any) => {
          this.savedTriggers.clear();
          if (data.trigger_templates.length){
            data.trigger_templates.forEach((trigger: any) => {
              this.savedTriggers.set(trigger.trigger_template_id, trigger);
              if (trigger?.category?.length) {
                trigger?.category.split(' ').map((category: string) => {
                  this.availableCategories.add(category);
                })
              }
            })
            this.clearFilters();
          }
        }
      )
    },
    createNewTrigger() {
      this.savedTriggers.set(0, {
          title: '',
          description: '',
          category: '',
          trigger: {
            value: '',
            topic: '',
            type: ''
          },
          trigger_template_id: 0
        });
      this.filterSavedTriggers();
    },
    confirmTrigger(trigger: any) {
      this.selectedTrigger = structuredClone(trigger);
    },
    editTrigger(newTrigger: any) {
      if (newTrigger.trigger_template_id === 0){
        this.savedTriggers.delete(0);
        delete newTrigger.trigger_template_id;
        ApiManager.addTrigger(newTrigger, () => {}, (res:any) => {
          this.filterSavedTriggers();
          console.log(res);
        })
      } else {
        console.log('update trigger', newTrigger);
        ApiManager.updateTrigger(newTrigger.trigger_template_id, newTrigger, () => {}, (res:any) => {
          this.filterSavedTriggers();
          console.log(res);
        })
      }
    },
    deleteTrigger(triggerId: number) {
      if (triggerId === 0){
        this.savedTriggers.delete(0);
        this.filterSavedTriggers();
      } else {
        ApiManager.deleteTrigger(triggerId, () => {}, (res: any) => {
          console.log('Custom Trigger deleted', triggerId)
          this.savedTriggers.delete(triggerId);
          this.filterSavedTriggers();
        });
      }
    },
    submitTrigger(trigger: any) {
      console.log('submitting: ', trigger);
      ApiManager.sendCustomTrigger(trigger, () => {}, (res: any) => {
        console.log('successfully submitted trigger:', trigger);
      });
    },
    onTriggerAdded(args: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      const newTrigger = msg.trigger_template;
      this.savedTriggers.set(Number(msg.trigger_template_id), newTrigger);
      this.filterSavedTriggers();
    },
    onTriggerUpdated(args: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      const newTrigger = msg.trigger_template;
      this.savedTriggers.set(Number(msg.trigger_template_id), newTrigger);
      this.filterSavedTriggers();
    },
    onTriggerDeleted(args: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      this.savedTriggers.delete(Number(msg.trigger_template_id));
      console.log(this.savedTriggers)
      this.filterSavedTriggers();
    },
    filterSavedTriggers() {
      const filtered = Array.from(this.savedTriggers.values())
        .filter((trigger: any) => {
          const check = !this.filters.text.length || // If there are no text specified - return all elements
            (trigger.title || '').toLowerCase().includes(this.filters.text.toLowerCase() || '') || // check for the matching text in the title
            (trigger.description || '').toLowerCase().includes(this.filters.text.toLowerCase() || ''); // check for the matching text in the description
          return check;
        })
        .filter((trigger: any) => {
          if (this.filters.categories?.length && this.filters.categories === 'None'){
            return !trigger.category?.length
          }
          const check = !this.filters.categories.length || // If there are no category selected - return all elements
            (trigger.category || '').includes(this.filters.categories || ''); // Check for the matching category
            // TODO - check categories as an array instead of string to use multiple categories.
          return check;
        });

      this.filteredSavedTriggers = filtered;
    },
    clearFilters() {
      this.filters.text = '';
      this.filters.categories = '';
      this.filterSavedTriggers();
    },
  },
  mounted() {
    this.availableCategories.add('None');
  },
  created() {
    ApiManager.subscribeToUpdates([
      [ApiManager.sse_message_types.trigger_template_added, this.onTriggerAdded],
      [ApiManager.sse_message_types.trigger_template_edited, this.onTriggerUpdated],
      [ApiManager.sse_message_types.trigger_template_removed, this.onTriggerDeleted],
    ])
    this.getAllCustomTriggers()
  },
})
</script>

<style>
</style>
