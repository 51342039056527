import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LiveView from "@/views/LiveView.vue";
import OrdersView from "@/views/OrdersView.vue";
import BarcodeView from "@/customfeatures/knauf/BarcodeView.vue";
import {parse} from 'yaml';
import configuration from '!raw-loader!../configuration.yml';
import VehiclesOverview from "@/views/VehiclesOverview.vue";
import UserManagement from "@/views/UserManagement.vue";
import QuickRoutes from "@/views/QuickRoutes.vue";
import AdministrationView from "@/views/AdministrationView.vue";
import { currentUserState } from '@/datamanagers/ReactiveStates';
import CustomTriggers from '@/views/CustomTriggers.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/orders',
        name: 'orders',
        component: OrdersView,
        meta: {
          requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'liveview',
        component: LiveView
    },
    {
        path: '/quick_routes',
        name: 'quick_routes',
        component: QuickRoutes,
        meta: {
          requiresAuth: true
        }
    },
    {
        path: '/custom_triggers',
        name: 'custom_triggers',
        component: CustomTriggers,
        meta: {
          requiresAuth: true
        }
    },
    {
        path: '/agv_details/:id',
        name: 'agv_details',
        component: VehiclesOverview,
        meta: {
          requiresAuth: true
        }
    },
    {
        path: '/user_management',
        name: 'user_management',
        component: UserManagement,
        meta: {
          requiresAuth: true
        }
    },
    {
      path: '/administration_control_panel',
      name: 'administration_control_panel',
      component: AdministrationView,
      meta: {
        requiresAuth: true,
        access_level: 0
      }
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to the main page.
    if (!currentUserState?.username?.length) {
      next({ name: 'liveview' });
    } else {
      const roleLevel = currentUserState?.role?.role_level !== undefined ? currentUserState?.role?.role_level : 999;
      if (Number(roleLevel) > Number(to.meta.access_level)) {
        next({ name: 'liveview' }); // restrict route and redirect to the liveView
      }
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
