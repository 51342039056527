<template>
  <TransitionRoot appear :show="true" as="template">
    <Dialog as="div" @close="closeModal(false)" class="relative z-10">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
            <DialogPanel
                class="w-full max-w-md transform overflow-visible rounded-2xl color_background_light p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                  v-if="customizable && savedTriggers.size"
                  as="h3"
                  class="text-lg font-medium leading-6 flex items-center">
                <Dropdown
                  :elem-list="Array.from(savedTriggers.values()).map((triggerTemplate: any) => triggerTemplate.trigger_template_id + (triggerTemplate.title.length ? ': '+triggerTemplate.title : ''))"
                  :prompt="'Trigger Templates'"
                  @selectedEl="(triggerTemplate: any, index: number) => fillDataFromTemplate(triggerTemplate, index)"/>
              </DialogTitle>
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 color_enabled flex items-center">
                {{ triggerTemplate?.title }}
              </DialogTitle>
              <DialogDescription
                v-if="triggerTemplate?.description?.length"
                as="h3"
                class="text-md leading-6 color_enabled">
                {{ triggerTemplate?.description}}
              </DialogDescription>
              <DialogDescription
                v-if="triggerTemplate?.category?.length"
                as="h3"
                class="text-md leading-6 color_enabled">
                {{ triggerTemplate.category }}
              </DialogDescription>


              <DialogDescription
                as="h4"
                class="ml-4 mb-2 text-sm leading-6">
                <span class="-ml-2 color_enabled">Data: <br /></span>
                <StyledInput v-if="customizable" placeholder="Topic" v-model="triggerTemplate.trigger.topic" :floating="true"/>
                <span v-else class="color_enabled">Topic: {{ triggerTemplate.trigger.topic }}<br /></span>
                <StyledInput v-if="customizable" placeholder="Value" v-model="triggerTemplate.trigger.value" :floating="true"/>
                <span v-else class="color_enabled">Value: {{ triggerTemplate.trigger.value }}<br /></span>
                <StyledInput v-if="customizable" placeholder="Type" v-model="triggerTemplate.trigger.type" :floating="true"/>
                <span v-else class="color_enabled">Type: {{ triggerTemplate.trigger.type }}</span>
              </DialogDescription>

              <div>
                <div v-if="useConfig().localConfig.hard_vehicle_restriction || staticVehicleId.length" class="color_enabled mb-2">{{ $t('str_vehicle') }}: <span class="ml-6">{{ `${vehicleStates?.get(staticVehicleId).name}-${staticVehicleId}` }}</span></div>
                <VehicleDropdown v-else class="mb-2" :vehicles="vehicleStates" :label="$t('str_vehicle')+'*'" :show-automatic="false"
                                @vehicleSelected="setVehicleId"/>

                <div class="mt-4 flex justify-end">
                  <button
                      type="button"
                      class="flex justify-center rounded-md border px-4 py-2 text-sm font-medium button "
                      @click="closeModal(true)"
                      :disabled="vehicleId === null || !vehicleId.length"
                  >
                    Send Trigger
                  </button>

                  <button
                      type="button"
                      class="flex justify-center rounded-md border mx-4 px-4 py-2 text-sm font-medium button2 "
                      @click="closeModal(false)"
                  >
                    {{ $t('str_cancel') }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon, ChevronDownIcon} from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/vue/24/solid';
import {Dialog, DialogDescription, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,} from '@headlessui/vue'
import VehicleDropdown from "@/components/VehicleDropdown.vue";
import Dropdown from "@/components/Dropdown.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import { useConfig } from '@/main';
import * as ApiManager from "../../network/ApiManager";
import { NodesManager } from '@/datamanagers/NodesManager';
import { VehicleState, vehicleStates } from '@/dtos/VehicleState';
import StyledInput from '../StyledInput.vue';

export default defineComponent({
  name: "TriggerConfirmationPopup",
  data() {
    return {
      vehicleId: String(useConfig().localConfig.default_vehicle || ""),
      savedTriggers: new Map<number, {
          title: string,
          description: string,
          category: string,
          trigger:
          {
            value: string,
            topic: string,
            type: string
          },
          trigger_template_id?: number
        }>(),
      triggerTemplate: {} as {
        title: string,
        description: string,
        category: string,
        trigger:
        {
          value: string,
          topic: string,
          type: string
        }
        trigger_template_id?: number
      },
    }
  },
  props: {
    selectedTrigger:
      {
        type: null,
        default: null
      },
    customizable: {
        type: Boolean,
        default: false
      },
    staticVehicleId: {
      type: String,
      default: ''
    }
  },
  watch: {

  },
  computed: {},
  mounted() {
    this.triggerTemplate = this.selectedTrigger;
    this.vehicleId = this.staticVehicleId;
    if (this.customizable) {
      ApiManager.getAllTriggers(
        () => {},
        (data: any) => {
          if (data.trigger_templates.length){
            data.trigger_templates.forEach((trigger: any) => {
              this.savedTriggers.set(trigger.trigger_template_id, trigger);
            });
          }
        }
      )
    }
  },
  methods: {
    closeModal(confirmed: boolean) {
      this.$emit('triggerConfirmed', confirmed, {
        vehicle_id: this.vehicleId, trigger: {...this.triggerTemplate.trigger}});
    },
    fillDataFromTemplate(newTemplate: any, index: number) {
      const selectedTemplateId = Number(newTemplate.split(':')[0])
      if (this.savedTriggers.has(selectedTemplateId)) {
        //@ts-ignore
        this.triggerTemplate = this.savedTriggers.get(selectedTemplateId);
      }
    },
    setVehicleId(selectedId: string) {
      this.vehicleId = selectedId
    },
  }
})


</script>

<style scoped>
</style>
