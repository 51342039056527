<template>
  <!-- New edge modal. Creates new edge -->
  <div id="new-edge-modal" class="mx-auto flex-col h-full w-full">
    <!-- <div class="col-span-8 lg:col-span-9"></div> -->
    <main class="color_background_dark  mx-6">
      <ul role="list">
        <li class="flex flex-row  pt-4 ">
          <p class="color_disabled -ml-2 text-sm uppercase">{{ $t("str_edit_edge") }}</p>
          <p v-show="edgeId !== -1 && edgeId !== undefined" class="color_disabled ml-2 text-sm uppercase">{{ `(${edgeId})` }}
          </p>
          <div class="flex flex-grow" />
          <button type="button" @click="loadPrevEdge" :disabled="edgeIndexInStack === 0"
            class="rounded-md text-gray-400 hover:text-gray-500 disabled:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <button type="button" @click="loadNextEdge" :disabled="edgeIndexInStack + 1 === edgeStack.length"
            class="rounded-md text-gray-400 hover:text-gray-500 disabled:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <ChevronRightIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <button type="button" @click="closeModal"
            class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="mb-1 color_enabled w-full">
            {{ $t("str_edge_description") }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="mb-1 w-full ">
            <input class="w-full rounded" v-model="addEdgeForm.edgeDescription">
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_start_node") }}:
          </p>
          <p class="mx-4 color_enabled font-medium"> {{ addEdgeForm.startNodeId }}</p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_end_node") }}:
          </p>
          <p class="mx-4 color_enabled font-medium"> {{ addEdgeForm.endNodeId }}</p>
        </li>
        <li class="flex flex-row flex-grow ">
          <p class=" mb-1  color_enabled flex ">
            {{ $t("str_edit_trajectory") }}
          </p>
          <input type="checkbox" class="m-2 flex button" v-model="editorState.editingTrajectory">
        </li>
        <li v-if="edgeId" class="flex flex-col flex-grow ">
          <ul v-if="connectedComponents.size" class="color_enabled">
            <li>{{ $t('str_connected_components') }}:</li>
            <li v-for="component in Array.from(connectedComponents, ([key, value]) => ({ id: key, edges: value }))"
              class="mb-2">
              <ul>
                <li class="flex">
                  <button v-if="liveViewEditorState.editedConnectedComponent && liveViewEditorState.editedConnectedComponent.id === component.id && liveViewEditorState.editedConnectedComponent.id !== 'new'"
                    @click="() => deleteConnectedComponent(component.id)" class="mr-1">
                    <TrashIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  {{ component.id !== 'new' ? $t('str_component')+ '-' + component.id : $t('str_new_component') }}
                  <button v-if="!liveViewEditorState.editedConnectedComponent"
                    @click="() => editConnectedComponent(component.id)" class="ml-4">
                    <PencilIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </li>
                <li v-if="liveViewEditorState.editedConnectedComponent?.id !== component.id"
                  v-for="edgeId in component.edges" class="ml-4 text-sm flex">{{ 'Edge-' + edgeId }}</li>
                <li
                  v-if="liveViewEditorState.editedConnectedComponent && liveViewEditorState.editedConnectedComponent.id === component.id"
                  v-for="edgeId in liveViewEditorState.editedConnectedComponent.edges" class="ml-4 text-sm flex" :key="edgeId">
                  <button @click="() => deleteEdgeFromComponent(component, edgeId)" class="mr-1">
                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                  </button>{{ 'Edge-' + edgeId }}</li>
                <li v-if="liveViewEditorState.editedConnectedComponent?.id === component.id">
                  <div class="flex mt-1">
                    <button @click="cancelEditingConnectedComponent" class="border w-full rounded-md p-1 button2 md:mr-1">
                      {{  $t('str_cancel') }}
                    </button>
                    <button @click="saveConnectedComponent" class="border w-full rounded-md p-1 button2 md:ml-1">
                      {{  $t('str_save') }}
                    </button>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <div class="mx-8 mt-2 mb-4">
            <button v-if="!liveViewEditorState.editedConnectedComponent" @click="() => editConnectedComponent()"
              class="border w-full rounded-md p-1 button2">
              {{ $t("str_create_component") }}
            </button>
          </div>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_length") }}:
          </p>
          <p class="mx-4 color_enabled font-medium"> {{ addEdgeForm.length.toFixed(2) }}</p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_max_speed_for_vehicle") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1 w-full ">
            <input class="w-full rounded" v-model="addEdgeForm.maxSpeed">
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_max_height_for_vehicle") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1 w-full ">
            <input class="w-full rounded" v-model="addEdgeForm.maxHeight">
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_min_height_for_vehicle") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1 w-full ">
            <input class="w-full rounded" v-model="addEdgeForm.minHeight">
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="mb-1  color_enabled">
            {{ $t("str_orientation") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="mb-1 w-full">
            <Dropdown :is-filterable="false" :elem-list="orientationOptions.map(elem => elem.key)"
              :prompt="selectedOrientation" :selected-item="selectedOrientation"
              @selectedEl="newOrientation => selectedOrientation = newOrientation" v-if="selectedOrientation.length">
            </Dropdown>
            <p v-if="showOrientationInput || !selectedOrientation.length" class="flex">
              <span class="color_enabled whitespace-nowrap">Angle (Rad):</span>
              <input class="w-full ml-1 rounded" v-model="addEdgeForm.orientation">
            </p>
          </p>
        </li>
        <li class="flex flex-row flex-grow flex-wrap mb-2">
          <p class=" mb-1  color_enabled">
            {{ $t("str_orientation_type") }}:
          </p>
          <fieldset>
            <legend class="sr-only">Notification method</legend>
            <div class="flex sm:items-center md:space-x-5 flex-wrap md:flex-nowrap">
              <div v-for="orientationType in getOrientationType" :key="orientationType.id" class="flex items-center">
                <input :id="orientationType.id" name="notification-method" type="radio"
                  :checked="orientationType.id === getOrientationType[1].id"
                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  @click="addEdgeForm.orientationType = orientationType.id" />
                <label :for="orientationType.id" class="ml-3 block color_enabled leading-6">{{
            orientationType.title
          }}</label>
              </div>
            </div>
          </fieldset>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_rotation_allowed") }}:
          </p>
          <input type="checkbox" class="m-2 flex button" v-model="addEdgeForm.rotationAllowed">
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_max_rotation_allowed") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  w-full">
            <input class="w-full rounded" v-model="addEdgeForm.maxRotationSpeed">
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_extra_edge_cost") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  w-full">
            <input class="w-full rounded" v-model="addEdgeForm.extraCost">
          </p>
        </li>
        <li class="flex flex-row flex-grow mx-8 my-2">
          <button @click="unblockEdge" class="border w-full rounded-md p-1 button2">
            {{ $t("str_unblock_edge") }}
          </button>
        </li>
      </ul>
      <br>
      <ul>
        <ActionsView :scope="1" :savedActions="actionArray" @resultActions="retrieveActions" />
        <li class="flex flex-row flex-grow mb-2">
          <button @click="saveEdge" class="mx-8 border w-full rounded-md py-1 button2">
            {{ $t("str_save_edge") }}
          </button>
        </li>
        <li class="flex flex-row flex-grow mb-2">
          <button type="button" class="mx-8 border w-full rounded-md py-1 deletebutton" @click="deleteEdge"
            v-show="edgeId !== -1 || edgeId !== undefined">
            <div class="inline-flex">
              <TrashIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <p class="ml-2">{{ $t("str_delete_edge") }}</p>
            </div>
          </button>
        </li>
      </ul>
    </main>
  </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/vue/20/solid'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { TrashIcon, PencilIcon } from '@heroicons/vue/24/outline'
import ActionsView from "@/components/ActionsView.vue";</script>

<script lang="ts">
import { defineComponent } from 'vue'
import { useConfig } from "@/main"
import * as ApiManager from "../../network/ApiManager";
import * as AlertManager from '@/datamanagers/AlertsManager'
import * as StorageManager from '@/datamanagers/StorageManager'
import { NodesManager } from "@/datamanagers/NodesManager";
import { EdgesManager } from "@/datamanagers/EdgesManager";
import { prod_safe_log } from '@/utils'
import { liveViewEditorState } from "@/dtos/AppState";
import { publishControlCenterMessage } from "@/network/MqttManager";
import Dropdown from '../Dropdown.vue';

export default defineComponent({
  name: "NewEdgeModal",
  data() {
    return {
      selected: {},
      editorState: liveViewEditorState,
      edgeId: -1,
      orientationOptions: [
        {
          key: this.$t('str_orientation_free'),
          value: null
        },
        {
          key: this.$t('str_orientation_front'),
          value: 0
        },
        {
          key: this.$t('str_orientation_left_side'),
          value: Math.floor(Math.PI / 2 * 10000) / 10000
        },
        {
          key: this.$t('str_orientation_right_side'),
          value: -Math.floor(Math.PI / 2 * 10000) / 10000
        },
        {
          key: this.$t('str_orientation_back'),
          value: Math.floor(Math.PI * 10000) / 10000
        }
      ],
      showOrientationInput: false,
      addEdgeForm: {
        edgeDescription: "",
        startNodeId: "",
        endNodeId: "",
        maxSpeed: 0,
        maxHeight: 0,
        minHeight: 0,
        orientation: null as null | number,
        orientationType: "TANGENTIAL",
        direction: "",
        rotationAllowed: true,
        maxRotationSpeed: "",
        extraCost: 0,
        trajectory: {
          inputPoints: [
            {
              x: 0,
              y: 0
            }
          ],
          degree: 0,
          knotVector: [
            0
          ],
          controlPoints: [
            {
              x: 0,
              y: 0,
              weight: 0
            }
          ]
        },
        length: 0,
        actions: [] as any[]
      },
      selectedOrientation: this.$t('str_orientation_free'),
      actionArray: [] as any[],
      oldEdge: null as any,
      edgeIndexInStack: 0,
      connectedComponents: new Map()
    }
  },
  props: {
    savedEdge: null as any,
    edgeStack: {
      type: null,
      default: null
    },
    selectedComponents: {
      type: Map,
      default: new Map()
    }
  },
  watch: {
    editorState: {
      handler: function (newVal) {
        if (!newVal.editMode) {
          this.closeModal();
        }
      },
      deep: true
    },
    savedEdge: {
      handler(newValue, oldValue) {
        this.edgeIndexInStack = this.edgeStack.indexOf(newValue?.edgeId);
        if (newValue == null) return;
        if(newValue.length !== this.oldEdge?.length) //If trajectory changes we should import the new length value. Since it is not editable by user - we can do this wihout user updates loss.
          this.addEdgeForm.length = newValue.length;
        // newValue and oldValue often times are the same object, so we need to manually copy the edge and then save it
        // as this.oldEdge
        if (!newValue.edgeId || newValue.edgeId !== this.oldEdge?.edgeId) {
          this.importSavedEdge(newValue);
        }
      },
      deep: true
    },
    selectedOrientation(newValue) {
      const filteredOrientation = this.orientationOptions.filter(elem => elem.key === newValue);
      if (filteredOrientation.length) {
        this.addEdgeForm.orientation = filteredOrientation[0].value;
      }
      this.showOrientationInput = newValue === this.$t('str_orientation_custom') && useConfig().config.configuration.allow_custom_edge_orientation;
    },
    selectedComponents: {
      handler(newValue) {
        this.connectedComponents = new Map(JSON.parse(JSON.stringify(Array.from(newValue))));
      },
      deep: true
    },
    'editorState.editMode': { handler: function (newValue) { if (!newValue) this.cancelEditingConnectedComponent() } }
  },
  computed: {
    getOrientationType() {
      return [{ id: "GLOBAL", title: "Global" }, { id: "TANGENTIAL", title: "Tangential" }]
    }
  },
  mounted() {
    this.importSavedEdge(this.savedEdge);
    if (useConfig().config.configuration.allow_custom_edge_orientation) {
      this.orientationOptions.push({key: this.$t('str_orientation_custom'), value: this.addEdgeForm.orientation || 0})
    }
  },
  methods: {
    closeModal() {
      this.cancelEditingConnectedComponent();
      this.oldEdge = null;
      this.actionArray = [];
      this.$emit("closeModal");
    },
    importSavedEdge(newEdge: any) {
      this.cancelEditingConnectedComponent();
      const newEdgeCopy = structuredClone(newEdge);
      this.oldEdge = newEdgeCopy
      this.edgeId = newEdgeCopy.edgeId
      this.addEdgeForm.startNodeId = newEdgeCopy.startNodeId
      this.addEdgeForm.endNodeId = newEdgeCopy.endNodeId
      this.addEdgeForm.edgeDescription = newEdgeCopy?.edgeDescription ?? ""
      this.addEdgeForm.maxSpeed = newEdgeCopy?.maxSpeed ?? 0
      this.addEdgeForm.maxHeight = newEdgeCopy?.maxHeight ?? 0
      this.addEdgeForm.minHeight = newEdgeCopy?.minHeight ?? 0
      this.addEdgeForm.orientation = newEdgeCopy?.orientation ?? null
      this.addEdgeForm.orientationType = newEdgeCopy?.orientationType === this.getOrientationType[0].id ?
        this.getOrientationType[0].id : this.getOrientationType[1].id
      this.addEdgeForm.direction = newEdgeCopy?.direction ?? 0
      this.addEdgeForm.rotationAllowed = newEdgeCopy?.rotationAllowed ?? true
      this.addEdgeForm.maxRotationSpeed = newEdgeCopy?.maxRotationSpeed ?? 0
      this.addEdgeForm.length = newEdgeCopy?.length ?? 0
      this.addEdgeForm.extraCost = newEdgeCopy?.extraCost ?? 0

      const customOrientationOption = this.orientationOptions.filter((e: any) => e.key === this.$t('str_orientation_custom'))
      if (customOrientationOption.length)
        customOrientationOption[0].value = Math.floor(newEdgeCopy.orientation * 10000)/10000;

      if (newEdgeCopy.orientation === undefined || newEdgeCopy.orientation === null) {
        this.selectedOrientation = this.$t('str_orientation_free');
      } else {
        const filteredOrientation = this.orientationOptions?.filter(elem => elem.value == Math.floor(newEdgeCopy.orientation * 10000)/10000);
        this.selectedOrientation = filteredOrientation.length ? filteredOrientation[0].key : useConfig().config.configuration.allow_custom_edge_orientation ? this.$t('str_orientation_custom') : '';
      }

      this.actionArray = [];
      if (newEdgeCopy?.actions?.length > 0)
        this.actionArray = newEdgeCopy.actions.map((obj: any) => {
          const mergedParamArray =
            [...obj.actionParameters.reduce((map: any, obj: any) => map.set(obj.key, obj), new Map()).values()];
          return {action: {...obj.action}, actionParameters: mergedParamArray, predefined_action: true, blockingType: obj.blockingType, actionSequenceId: obj.actionSequenceId}
        })
    },
    saveEdge() {
      const payload = this.addEdgeForm;
      if (this.validateEdge(payload)) {

        // We edit trajectory outside of the modal, so we need to add it to the payload before saving edge
        const trajectory = EdgesManager.trajectories.get(NaN);
        payload.trajectory = trajectory?.nurbsCurveVda ?? this.savedEdge.trajectory;

        if (!payload.trajectory !== undefined)
          payload.trajectory.inputPoints = trajectory.inputPoints;

        for (let action of payload.actions) {
          action.actionParameters = action.actionParameters.filter((parameter: any) => parameter.key != '' && parameter.value != '')
        }
        if (this.edgeId === -1 || this.edgeId == undefined) {
          ApiManager.addEdge(payload, () => prod_safe_log('Could not add edge.'),
            () => {
              console.log('Edge successfully added.')
              this.closeModal()
            });
        } else {
          ApiManager.editEdge(this.edgeId, payload, () => prod_safe_log('Could not edit edge.'),
            () => {
              console.log('Edge successfully edited.')
              this.closeModal()
            });
        }
      }
    },
    validateEdge(edge: any) {
      if (edge.startNodeId === "" || edge.endNodeId === "" || edge.maxSpeed === "" || edge.maxHeight === "" || edge.rotationAllowed === "" || edge.rotationSpeed === "" || edge.direction === "") {
        console.log("Edge input invalid")
        AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_adding_new_edge"), this.$t("str_fill_form_correctly")))
        return false;
      }
      return true
    },
    deleteEdge() {
      ApiManager.deleteEdge(this.edgeId, () => prod_safe_log('Could not edit edge.'),
        () => {
          this.closeModal()
          console.log('Edge successfully deleted.')
        });
    },
    loadNextEdge() {
      const nextIndexInStack = Math.min(this.edgeIndexInStack + 1, this.edgeStack.length - 1);
      if (this.edgeStack.length < 2 || this.edgeIndexInStack === nextIndexInStack) return; // safety check to prevent runtime errors when there is only 1 item in the list
      this.edgeIndexInStack = nextIndexInStack;
      this.$emit("newStackIdLoaded", this.edgeIndexInStack);
    },
    loadPrevEdge() {
      const prevIndexInStack = Math.max(this.edgeIndexInStack - 1, 0);
      if (this.edgeStack.length < 2 || this.edgeIndexInStack === prevIndexInStack) return; // safety check to prevent runtime errors when there is only 1 item in the list
      this.edgeIndexInStack = prevIndexInStack;
      this.$emit("newStackIdLoaded", this.edgeIndexInStack);
    },
    retrieveActions(newActions: any[]) {
      this.addEdgeForm.actions = newActions
    },
    unblockEdge() {
      const mqttConfig = useConfig().config.configuration.mqtt
      const topic = `${mqttConfig.emmInterfaceName}/${mqttConfig.emmVersion}/${mqttConfig.manufacturer}/${mqttConfig.ccSubtopic}/unblockGraph`
      const message = { edgeId: this.edgeId }
      publishControlCenterMessage(mqttConfig,
        window.location.hostname,
        window.location.protocol,
        topic,
        message
      )
    },
    editConnectedComponent(component_id?: number) {
      if (component_id === undefined) {
        const newConnectedComponent = {id: 'new', edges: [this.edgeId]}
        this.connectedComponents.set(newConnectedComponent.id, newConnectedComponent.edges);
        liveViewEditorState.editedConnectedComponent = newConnectedComponent;
      } else {
        liveViewEditorState.editedConnectedComponent = { id: component_id, edges: this.connectedComponents.get(component_id) };
      }
    },
    saveConnectedComponent() {
      if (this.editorState.editedConnectedComponent) {
        if (this.editorState.editedConnectedComponent.id === 'new') {
          this.$emit("createConnectedComponent", this.editorState.editedConnectedComponent.edges);
        } else {
          this.$emit("updateConnectedComponent", this.editorState.editedConnectedComponent.id, this.editorState.editedConnectedComponent.edges);
        }
        this.cancelEditingConnectedComponent();
      }
    },
    deleteConnectedComponent(component_id: number) {
      this.$emit("deleteConnectedComponent", component_id);
      this.cancelEditingConnectedComponent();
    },
    deleteEdgeFromComponent(component: { id: number | string, edges: number[] }, edge_id: number) {
      component.edges.splice(component.edges.indexOf(edge_id), 1); // Mutates edited component by reference
    },
    cancelEditingConnectedComponent() {
      liveViewEditorState.editedConnectedComponent = null;
      this.connectedComponents = new Map(JSON.parse(JSON.stringify(Array.from(this.selectedComponents))));
      console.log('cancel editing connected component');
    },
  }
})

</script>
