<template>
  <div class="mx-auto ">
    <main class="mx-6 my-4 flex-col">
      <div class="flex flex-row  py-4 ">
        <p class="color_disabled -ml-2 text-sm uppercase">{{ liveViewEditorState.newOrderData.orderTemplateId ? `${$t("str_editing_order")}: ${liveViewEditorState.newOrderData.orderTemplateId}` : $t("str_new_order") }}</p>
        <div class="flex flex-grow"/>
        <button type="button" @click="closeModal"
                class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 relative -right-2.5">
          <XMarkIcon class="h-7 w-7" aria-hidden="true"/>
        </button>
      </div>
      <ul role="list" class="mx-auto">
        <li v-if="useConfig().localConfig.hard_vehicle_restriction" class="flex flex-row flex-grow mb-2 color_enabled">
          <div class="mr-6">{{ $t('str_vehicle') }}:</div>
          <div>
            {{ (vehicleStates.has(vehicleId) ? vehicleStates.get(vehicleId)?.name + '-' : $t("str_automatic")) + vehicleId }}
          </div>
        </li>
        <li v-else class="flex flex-row flex-grow mb-2">
          <VehicleDropdown :vehicles="vehicles" :label="$t('str_vehicle')"
                           @vehicleSelected="setVehicleId"/>
        </li>
        <li class="flex flex-row flex-grow mb-2">
          <p class="color_enabled items-center mr-6">{{ $t("str_order_request_priority") }}: </p>
          <Dropdown :elem-list="priorityValues"
                    :prompt="priorityValues[orderRequestPriority]"
                    :default-selection-id="priorityValues[orderRequestPriority]"
                    @selectedEl="(el:string)=>orderRequestPriority=priorityValues.indexOf(el)"/>
        </li>
        <li v-for="(node, eventIdx) in nodeSequence">
          <div class="relative pb-2 ml-2">
            <span v-if="eventIdx !== nodeSequence.length - 1"
                  class="absolute top-6 left-4 -ml-px h-full w-0.5 bg-gray-400"
                  aria-hidden="true"/>
            <div class="relative flex space-x-3">
              <div>
                <span
                :class="['color_background_light', 'h-5 w-5 rounded-full flex items-center justify-center relative left-1.5 top-5']">
                <component v-if="!NodesManager.nodes.has(node.nodeId)" :is="ExclamationTriangleIcon" class="h-5 w-5 text-orange-500" aria-hidden="true"/>
                <component v-else-if="nodeSequence.length===1 || eventIdx === nodeSequence.length - 1"
                  :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                  aria-hidden="true"/>
                  <component v-else :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                </span>
              </div>
              <div v-if="nodeSequence.length !== 1" class="color_enabled absolute -left-8 top-2">
                <div class="-ml-0.5 h-6 w-6">
                  <button type="button" @click="shiftNodeInTheList(eventIdx, -1)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <ChevronUpIcon v-if="eventIdx !== 0" class="h-6 w-6"/>
                  </button>
                </div>
                <div class="-ml-0.5 h-6 w-6">
                  <button type="button" @click="shiftNodeInTheList(eventIdx, 1)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <ChevronDownIcon class="h-6 w-6" v-if="eventIdx !== nodeSequence.length - 1"/>
                  </button>
                </div>
              </div>
              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div class="flex justify-between w-full color_enabled">
                  <p class="">
                    {{ node.nodeId }}: {{ node.nodeDescription }}
                  </p>
                  <div class="relative -right-3 -mb-2 text-gray-400 hover:text-gray-500">
                  <button type="button" @click="removeNodeFromSequence(eventIdx)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <XMarkIcon class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-7 -mr-6 xl:mr-0">
              <input type="checkbox" id="default_actions" class="mx-1"
                     :checked="!nodeSequence.at(eventIdx).defaultActions"
                     @click="nodeSequence.at(eventIdx).defaultActions = !nodeSequence.at(eventIdx).defaultActions;">
              <label class="color_enabled"
                     @click="nodeSequence.at(eventIdx).defaultActions = !nodeSequence.at(eventIdx).defaultActions">
                {{ $t("str_custom_actions") }}
              </label>
            </div>
            <div v-show="!nodeSequence.at(eventIdx).defaultActions" class="ml-10 mt-2">
              <ActionsView :scope="0"
                           :compactView="Number(1)"
                           :savedActions="nodeSequence.at(eventIdx).actions"
                           @resultActions="(newActions) => setActions(eventIdx, newActions)"/>
            </div>
            <div v-if="nodeSequence.at(eventIdx).defaultActions && NodesManager.nodes.get(node.nodeId)?.actions?.length">
              <input class="switchCheckbox" type="checkbox" :id="`switchCheckbox-${eventIdx}`"/>
              <label class="switchLabel text-sm color_enabled ml-7" :for='`switchCheckbox-${eventIdx}`'>{{ $t("str_default_actions") }} ({{ NodesManager.nodes.get(node.nodeId).actions.length }})</label>
              <div class="color_enabled ml-7 text-sm switchBoxDropdown">
                <ul class="mt-2">
                  <li v-for="action in NodesManager.nodes.get(node.nodeId).actions" class="ml-2 mb-2">
                    {{ action.action.actionType }} ({{ action.action.blockingType }})
                    <ul v-if="action.action.actionParameters?.length">
                      <li class="ml-1 text-xs" v-for="actionParam in action.action.actionParameters">
                        {{ actionParam.key }}: {{ actionParam.value || '-' }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div v-if="!showAddNodeForm" class="mx-auto w-full color_background_dark rounded-xl transition-all hover:shadow hover:shadow-gray-500 hover:bg-gray-900 hover:bg-opacity-40 active:bg-gray-900 active:bg-opacity-70  active:shadow-none active:opacity-70">
            <div class="button2 flex color_enabled first-letter:flex-col px-4 py-1" @click="showAddNodeForm = true" >
              <PlusCircleIcon class="w-12 h-12 my-2 mx-auto" />
            </div>
          </div>

          <div v-else class="flex flex-row flex-wrap justify-end items-center xl:flex-nowrap">
            <div class="max-w-full w-full">
              <Dropdown
                class="max-w-md mb-0"
                style="margin-bottom:0;"
                isFilterable
                :elem-list="Array.from(NodesManager.nodes.values()).map((el:any) => `${el.nodeId}${el.nodeDescription ? ': '+el.nodeDescription : ''}`)"
                :prompt="$t('str_select_node')"
                name="node_id"
                @selectedEl="(elem) => selectedNodeToAdd = elem && elem.length ? (elem).split(': ')[0] : null"
                />
            </div>
            <div class="max-w-min flex mt-1 xl:mt-0">
              <button @click="addNodeToSequence" :disabled="!selectedNodeToAdd" class="ml-2 border w-9 h-9 rounded-md py-1 button2">
                <CheckIcon class="w-6 h-6 mx-auto" />
              </button>
              <button @click="() => {selectedNodeToAdd = null; showAddNodeForm = false;}" class="ml-2 border w-9 h-9 rounded-md py-1 deletebutton">
                <XMarkIcon class="w-6 h-6 mx-auto" />
              </button>
            </div>
          </div>
        </li>
        <li v-if="quickRouteMetaFormVisible" class="flex flex-col flex-grow my-4">
          <StyledInput class="!my-2" v-model="newOrderTemplate.title" placeholder="Title" :floating="true"/>
          <StyledInput class="!my-2" v-model="newOrderTemplate.description" placeholder="Description" :floating="true"/>
          <StyledInput class="!my-2" v-model="newOrderTemplate.category" placeholder="Category" :floating="true"/>
        </li>
        <li v-if="quickRouteMetaFormVisible" class="flex flex-row flex-grow my-4">
          <button @click="saveQuickRoute" class="mx-8 border w-full rounded-md py-1 button2" :disabled="!checkRouteValidity()">
            {{ $t("str_save_order_template") }}
          </button>
        </li>
        <li class="flex flex-row flex-grow my-4">
          <button @click="submitOrder" class="mx-8 border w-full rounded-md py-1 button" :disabled="!checkRouteValidity()">
            {{ $t("str_submit_order") }}
          </button>
        </li>
        <li v-if="!quickRouteMetaFormVisible" class="flex flex-row flex-grow my-4">
          <button @click="quickRouteMetaFormVisible = true" class="mx-8 border w-full rounded-md py-1 button2" :disabled="!checkRouteValidity()">
            {{ $t("str_create_order_template") }}
          </button>
        </li>
      </ul>
    </main>
  </div>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {XMarkIcon, ChevronUpIcon, ChevronDownIcon, ExclamationTriangleIcon, CheckIcon} from '@heroicons/vue/20/solid';
import VehicleDropdown from "@/components/VehicleDropdown.vue";
import ActionsView from "@/components/ActionsView.vue";
import Dropdown from "@/components/Dropdown.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import * as ApiManager from "@/network/ApiManager"
import * as AlertManager from "@/datamanagers/AlertsManager"
import {actionArrayChanged, getPriorityStrings, zip} from "@/utils";
import {liveViewEditorState} from "@/dtos/AppState";
import { useConfig } from '@/main';
import { vehicleStates } from '@/dtos/VehicleState';
import StyledInput from '../StyledInput.vue';
import { NodesManager } from '@/datamanagers/NodesManager';

export default defineComponent({
  name: "NewOrderModal",
  data() {
    return {
      nodeSequence: liveViewEditorState.newOrderData.nodes,
      orderRequestPriority: 1, //high priority
      vehicleId: String(useConfig().localConfig.default_vehicle || ""),
      priorityValues: getPriorityStrings(this),
      liveViewEditorState,
      quickRouteMetaFormVisible: !!liveViewEditorState.newOrderData.nodes.length,
      newOrderTemplate: {
        title: liveViewEditorState.newOrderData.title || '',
        description: liveViewEditorState.newOrderData.description || '',
        category: liveViewEditorState.newOrderData.category || '',
      },
      showAddNodeForm: false,
      selectedNodeToAdd: null as number | string | null,
    }
  },
  props:{
    orderData:
        {
          type: Object,
          default: null
        },
    vehicles:
        {
          type: Map,
          default: []
        }
  },
  watch: {
    "liveViewEditorState.locale"(newValue, oldValue) {
      this.priorityValues = getPriorityStrings(this)
      console.log("Lang updated", newValue, this.priorityValues)
    },
    "liveViewEditorState.newOrderData"(newValue, oldValue) {
      this.nodeSequence = newValue.nodes;
      this.newOrderTemplate = {
        title: newValue.title || '',
        description: newValue.description || '',
        category: newValue.category || '',
      };
      this.quickRouteMetaFormVisible = !!newValue.orderTemplateId;
    }
  },
  computed:{},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    shiftNodeInTheList(position: number, inc: number) {
      const shiftedNode = this.nodeSequence[position];
      const nextNode = this.nodeSequence[position + inc];
      this.nodeSequence[position] = nextNode;
      this.nodeSequence[position + inc] = shiftedNode;
    },
    removeNodeFromSequence(nodeIdx: number) {
      this.nodeSequence.splice(nodeIdx, 1);
    },
    addNodeToSequence() {
      const orderNode = NodesManager.nodes.get(Number(this.selectedNodeToAdd));
      this.nodeSequence.push({
          nodeId: orderNode.nodeId,
          nodeDescription: orderNode.nodeDescription,
          defaultActions: true
        });
      this.showAddNodeForm = false;
      this.selectedNodeToAdd = null
    },
    submitOrder(){
      if (this.nodeSequence.length > 0) {
        const nodes = this.nodeSequence.map((value: any) => {
          const actionNode = {
            node_id: value.nodeId,
            default_actions: value.defaultActions,
            actions: []
          }
          if (!value.defaultActions) {
            actionNode.actions = value?.actions?.map((actionVal: any) => {
              return {
                actionId: actionVal.action.actionId,
                actionParameters: actionVal.actionParameters
              }
            }) ?? []
          }
          return actionNode
        })
        ApiManager.createOrderRequest(nodes, new Date().toISOString(), String(this.vehicleId),
            this.orderRequestPriority,
            () => {
            },
            ((id: string) => {
              console.log("Created order request successfully")
              this.closeModal()
            }))
      } else {
        AlertManager.showAlert(AlertManager.createWarningAlert(this.$t("str_can_not_submit_order"),
            this.$t("str_order_should_have_2_nodes")))
      }

    },
    setVehicleId(selectedId: string) {
      this.vehicleId = selectedId
    },
    setActions(idx: number, actions: any[]) {
      const formattedActions = this.formatActionsForActionsView(actions);
      const dataChanged = actionArrayChanged(this.nodeSequence?.at(idx)?.actions, formattedActions)
      if (dataChanged) {
        this.nodeSequence.at(idx).actions = formattedActions
      }
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    },
    saveQuickRoute() {
      if (!this.nodeSequence.length){
        return AlertManager.showAlert(AlertManager.createWarningAlert(this.$t("str_can_not_submit_order"), this.$t("str_order_should_have_2_nodes")));
      }
      const nodes = this.nodeSequence.map((value: any) => {
        const actionNode = {
          node_id: value.nodeId,
          default_actions: value.defaultActions,
          actions: []
        }
        if (!value.defaultActions) {
          actionNode.actions = value?.actions?.map((actionVal: any) => {
            return {
              actionId: actionVal.action.actionId,
              actionParameters: actionVal.actionParameters
            }
          }) ?? []
        }
        return actionNode
      });
      const payload = {
        nodes,
      } as any;
      if (this.newOrderTemplate.title !== null)
        payload.title = this.newOrderTemplate.title;
      if (this.newOrderTemplate.description !== null)
        payload.description = this.newOrderTemplate.description;
      if (this.newOrderTemplate.category !== null)
        payload.category = this.newOrderTemplate.category;

      if (this.liveViewEditorState.newOrderData.orderTemplateId) {
        ApiManager.updateOrderTemplate(String(this.liveViewEditorState.newOrderData.orderTemplateId), payload,
          () => {
            const alert = AlertManager.createWarningAlert(this.$t("str_quick_route_not_saved"), this.$t("str_quick_route_not_saved1"))
            AlertManager.showAlert(alert)
          },
          ((id: string) => {
            // @ts-ignore
            const alert = AlertManager.createSuccessAlert(this.$t("str_quick_route_saved"))
            AlertManager.showAlert(alert)
            console.log("Saved quick order");
            this.closeModal();
            this.$router.push('/quick_routes');
          }))
      } else {
        ApiManager.saveOrderTemplate(payload,
          () => {
            const alert = AlertManager.createWarningAlert(this.$t("str_quick_route_not_saved"), this.$t("str_quick_route_not_saved1"))
            AlertManager.showAlert(alert)
          },
          ((id: string) => {
            // @ts-ignore
            const alert = AlertManager.createSuccessAlert(this.$t("str_quick_route_saved"))
            AlertManager.showAlert(alert)
            console.log("Saved quick order")
            this.closeModal();
            this.$router.push('/quick_routes');
          }))
      }
    },
    checkRouteValidity() {
      let isValid = this.nodeSequence.length > 0;
      this.nodeSequence.forEach((node: any) => {
        isValid = isValid && NodesManager.nodes.has(node.nodeId);
      });
      return isValid;
    }
  },
})


</script>

<style scoped>
.switchCheckbox{
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  top: -1000px;
  right:-10000px;
}

.switchLabel {
  display: block;
  position: relative;
}

.switchLabel::after{
  content: '';
  display:block;
  position:absolute;
  top:50%;
  right: 0;
  width: 0.45rem;
  height: 0.45rem;
  border-top: 0.125rem rgb(156 163 175 / var(--tw-text-opacity, 1)) solid;
  border-right: 0.125rem rgb(156 163 175 / var(--tw-text-opacity, 1)) solid;
  margin-top: -0.15rem;
  transform: rotate(45deg);
}

.switchBoxDropdown{
  display:none;
}

.switchCheckbox:checked~.switchLabel::after{
  transform: rotate(135deg);
  margin-top: -0.25rem;
}

.switchCheckbox:checked~.switchBoxDropdown{
  display:block;
}

</style>
