<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto  p-4 h-full ">
      <div class="flex flex-col fullscreen justify-between">
        <div class="flex w-auto flex-col pb-16">
          <!-- Legacy code. disabled for now until API will allow extended filtering -->

          <!-- <div v-show="orderFilterState.processed_selected == true || orderFilterState.canceled_selected == true"
               class="">
            <div v-show="orderFilterState.processed_selected == true"
                 class="relative inline-flex items-center rounded-3xl  color_background_dark mx-2 px-4 py-2 text-sm font-light color_enabled shadow-sm"
                 v-on:click="(orderFilterState.processed_selected = !orderFilterState.processed_selected)">
              {{ $t("str_bearbeitet") }}
              <xMarkIcon class="h-4 w-4 -mb-1 ml-1 color_enabled font-light" aria-hidden="true"/>
            </div>
            <div v-show="orderFilterState.canceled_selected == true"
                 class="relative inline-flex items-center rounded-3xl color_background_dark mx-2 px-4 py-2 text-sm font-light color_enabled shadow-sm"
                 v-on:click="(orderFilterState.canceled_selected = !orderFilterState.canceled_selected)">
              {{ $t("str_abgebrochen") }}
              <xMarkIcon class="h-4 w-4 -mb-1 ml-1 color_enabled font-light" aria-hidden="true" />
            </div>
          </div> -->

          <div class="flex sm:flex-wrap ">
            <div>
              <button type="button" @click="() => toggleFiltersForm()"
                      class="relative inline-flex items-center rounded-md border px-4 py-2 mx-1 text-sm font-medium color_enabled border_color_offwhite">
                <FunnelIcon v-if="!isFiltersApplied" class="-mx-1 h-5 w-5" aria-hidden="true"/>
                <FunnelIconSolid v-if="isFiltersApplied" class="-mx-1 h-5 w-5" aria-hidden="true"/>
              </button>
            </div>
            <div v-if="showFiltersForm" class="flex flex-col w-[calc(100%-5rem)]" style="min-width: 300px;">
              <form v-on:submit.prevent="applyFilters" v-on:reset="clearFilters" class="flex flex-wrap w-full">
                <div class="color_enabled text-lg text-medium w-full h-9 mb-2 ml-2 items-center flex">{{ $t("str_filter_by") }}:</div>
                <div class="flex flex-col w-1/3 px-2">
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40">{{ $t("str_vehicle_id") }}:</span>
                    <Dropdown
                      class="max-w-md mb-0"
                      style="margin-bottom:0;"
                      isFilterable
                      :defaultSelectionId="filtersForm.vehicle_id"
                      :elem-list="vehicles"
                      :prompt="$t('str_select_vehicle_id')"
                      name="vehicle_id"
                      @selectedEl="(elem) => filtersForm.vehicle_id = elem"
                      />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">{{ $t("str_order_id") }}:</span>
                    <div class="max-w-md mb-0">
                      <styled-input name="order_id_substr" class="mt-0 mb-0" type="text" v-model="filtersForm.order_id_substr"/>
                    </div>
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">{{ $t("str_request_id") }}:</span>
                    <div class="max-w-md mb-0">
                      <styled-input name="order_request_id_substr" class="mt-0 mb-0" style="margin:0;" type="text" v-model="filtersForm.order_request_id_substr"/>
                    </div>
                  </div>
                  <div class="flex mt-2">
                    <button class="mx-1 border w-full rounded-md py-2 text-sm button2" type="submit">{{ $t("str_apply_filters") }}</button>
                    <button class="mx-1 border w-full rounded-md py-2 text-sm button" type="reset">{{ $t("str_clear_filters") }}</button>
                  </div>
                </div>
                <div class="flex-flex-col w-1/3 px-2">
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40">{{ $t('str_filters_show')}}:</span>
                    <Dropdown
                      class="max-w-md mb-0"
                      style="margin-bottom:0;"
                      :defaultSelectionId="filtersForm.view_list"
                      :elem-list="view_list"
                      name="view_list"
                      @selectedEl="(elem) => filtersForm.view_list = elem"
                      />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">{{ $t('str_filters_node') }}:</span>
                    <div class="max-w-md mb-0">
                      <styled-input name="node_substr" class="mt-0 mb-0" type="text" v-model="filtersForm.node_substr"/>
                    </div>
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">{{ $t('str_filters_key_node') }}:</span>
                    <div class="max-w-md mb-0">
                      <styled-input name="key_node_substr" class="mt-0 mb-0" style="margin:0;" type="text" v-model="filtersForm.key_node_substr"/>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="flex w-full justify-end">
              <div class="flex">
                <span class="color_enabled mr-2 whitespace-nowrap">{{ $t('str_orders_per_page') }}:</span>
                <Dropdown
                  class="mb-0"
                  style="margin-bottom:0; min-width:80px;"
                  :defaultSelectionId="orders_pagination"
                  :elem-list="pagination_options"
                  @selectedEl="changeOrdersPagination"
                  />
              </div>
            </div>
          </div>
          <div class="relative">
            <table class="table-auto w-full text-center" style="white-space: pre-line" >
              <thead>
              <tr class="text-sm uppercase border-b-[1px] border_color_offwhite">
                <th>
                  <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]" @click="sort('orderId')">
                    <a href="#" class="font-light pl-8 py-4">
                      {{ $t("str_order_id") }}
                    </a>
                    <ChevronUpIcon class="h-5 w-5 my-4"
                                  v-if="(currentSortDir === 'asc' && order_settings.currentSort === 'orderId' && sort_enabled)"
                                  aria-hidden="true"/>
                    <ChevronDownIcon class="h-5 w-5 my-4"
                        v-else-if="(currentSortDir === 'desc' && order_settings.currentSort === 'orderId' && sort_enabled)"
                        aria-hidden="true" />
                      <div v-else class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                      <a href="#" class="font-light pl-8 py-4">
                        {{ $t("str_request_id") }}
                      </a>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                      <a href="#" class="font-light pl-8 py-4">
                        {{ $t("str_vehicle") }}
                      </a>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                      <a href="#" class="font-light pl-8 py-4">
                        {{ $t("str_nodes") }}
                      </a>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>

                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]" @click="sort('canceled')">
                      <a href="#" class="font-light pl-8 py-4">{{ $t("str_order_status") }}</a>
                      <ChevronUpIcon class="h-5 w-5 my-4"
                                    v-if="(currentSortDir === 'asc' && order_settings.currentSort === 'canceled' && sort_enabled)"
                                    aria-hidden="true"/>
                      <ChevronDownIcon class="h-5 w-5 my-4"
                                      v-else-if="(currentSortDir === 'desc' && order_settings.currentSort === 'canceled' && sort_enabled)"
                                      aria-hidden="true"/>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                      <a href="#" class="font-light pl-8 py-4">{{ $t("str_order_errors") }}</a>
                      <ChevronUpIcon class="h-5 w-5 my-4"
                                    v-if="(currentSortDir === 'asc' && order_settings.currentSort === 'canceled' && sort_enabled)"
                                    aria-hidden="true"/>
                      <ChevronDownIcon class="h-5 w-5 my-4"
                                      v-else-if="(currentSortDir === 'desc' && order_settings.currentSort === 'canceled' && sort_enabled)"
                                      aria-hidden="true"/>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                <th>
                  <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                    <a href="#" class="font-light pl-8 py-4">
                      {{ $t("str_order_request_priority") }}
                    </a>
                    <div class="h-5 w-5 my-4"></div>
                  </div>
                </th>
                <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]"
                        @click="sort('start_time')">
                      <a href="#" class="font-light pl-8 py-4">{{ $t("str_start_time") }}</a>
                      <ChevronUpIcon class="h-5 w-5 my-4"
                                    v-if="(currentSortDir === 'asc' && order_settings.currentSort === 'start_time' && sort_enabled)"
                                    aria-hidden="true"/>
                      <ChevronDownIcon class="h-5 w-5 my-4"
                        v-else-if="(currentSortDir === 'desc' && order_settings.currentSort === 'start_time' && sort_enabled)"
                        aria-hidden="true" />
                      <div v-else class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th>
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]" @click="sort('end_time')">
                      <a href="#" class="font-light pl-8 py-4">{{ $t("str_end_time") }}</a>
                      <ChevronUpIcon class="h-5 w-5 my-4"
                                    v-if="(currentSortDir === 'asc' && order_settings.currentSort === 'end_time' && sort_enabled)"
                                    aria-hidden="true"/>
                      <ChevronDownIcon class="h-5 w-5 my-4"
                                      v-else-if="(currentSortDir === 'desc' && order_settings.currentSort === 'end_time' && sort_enabled)"
                                      aria-hidden="true"/>
                      <div v-else class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                  <th class="inline-flex">
                    <div :class="[sort_enabled ? 'header' : 'color_disabled', 'inline-flex' ]">
                      <a href="#" class="font-light pl-8 py-4">
                        {{ $t("str_actions") }}
                      </a>
                      <div class="h-5 w-5 my-4"></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="text-sm font-light color_enabled">

              <!-- order requests -->
              <tr v-if="!isFiltersApplied || (!appliedFilters.order_id_substr.length && appliedFilters.view_list !== $t('str_filters_orders'))" v-for="order_request in Array.from(order_requests.values())" :key="order_request['orderRequestId']"
                  class="border-b-[1px] border_color_offwhite">
                <td></td>
                <td>{{ order_request['orderRequestId'] }}</td>
                <td>{{ vehicleStates.get(String(order_request['vehicle_id']))?.name }}-{{
                    order_request['vehicle_id']
                  }}
                </td>
                <td>{{
                    order_request['nodes']?.map((el: any) => `${el.nodeId}: ${el?.nodeDescription?.length ? el.nodeDescription : '-'}`)
                        .join("\n")
                  }}
                </td>
                <td>
                  {{ order_request['canceled'] == false ? $t("str_order_request_queued") : $t("str_order_canceled") }}
                </td>
                <td></td>
                <td>{{
                    `${order_request['priority']}, ${getPriorityStringsWithCtx[order_request['priority']] ?? ''}`
                  }}
                </td>
                <td>{{ formatTimeString(Date.parse(order_request['start_time']+'+00:00'), true) }}</td>
                <td></td>
                <td class="align-end m-2 py-2 relative text-right">
                  <OptionsDropdown :options="[
                    {title: $t('str_order_options_delete'), handler: () => confirm(() => onRemoveOrderRequest(order_request)), hidden: !!useConfig().config.configuration.disable_order_deletion},
                    {title: $t('str_order_options_cancel'), handler: () => onCancelOrderRequest(order_request)},
                  ]"></OptionsDropdown>
                </td>
              </tr>

              <!--orders-->
              <tr v-if="!isFiltersApplied || appliedFilters.view_list !== $t('str_filters_order_requests')"
                v-for="order in Array.from(orders.values()).reverse()" :key="order['orderId']"
                :class='["border-b-[1px] border_color_offwhite", (order.just_updated ? "updated_blink" : "")]'>
                <td>{{ order['orderId'] }}</td>
                <td>{{ order['orderRequestId'] || 'Auto' }}</td>
                <td>{{ vehicleStates.get(String(order['vehicle_id']))?.name }}-{{ order['vehicle_id'] }}</td>
                <td>{{
                    order['key_nodes']?.length ?
                      order['key_nodes']
                        .map((node: any) => `${node.nodeId}: ${node.nodeDescription?.length ? node.nodeDescription : '-'}`)
                        .join("\n") :
                      order['nodes']?.length ?
                        `${order['nodes'][0]?.nodeId}: ${order['nodes'][0]?.nodeDescription?.length ? order['nodes'][0].nodeDescription : '-'}
                        ${order['nodes'][order['nodes']?.length - 1].nodeId}: ${order['nodes'][order['nodes']?.length - 1].nodeDescription?.length ? order['nodes'][order['nodes']?.length - 1].nodeDescription : '-'}`
                      : ''
                  }}
                </td>
                <td>
                  {{
                    order['error_code'] !== null && order['error_code']?.length>0 ? $t("str_order_failed") :
                    order['canceled'] === true ? $t("str_order_canceled") :
                    order['end_time'] == null ? $t("str_order_in_progress") :
                      $t("str_order_completed")
                  }}
                </td>
                <td>
                  <div class="flex flex-row items-center flex-grow">
                    <Popover v-if="order['error_code']?.length>0" v-slot="{ open }" class="relative flex-grow">
                      <PopoverOverlay class="fixed inset-0 bg-black opacity-30"/>
                      <PopoverButton
                          :class="open ? '' : 'text-opacity-90'"
                          class="relative inline-flex items-center py-2  button2">
                        <p class="flex items-center ">{{ order['error_code'] }}</p>
                        <ChevronDownIcon
                            :class="open ? '' : 'text-opacity-70'"
                            class="h-5 w-5 text_enabled transition duration-150 ease-in-out group-hover:text-opacity-80"
                            aria-hidden="true"/>
                      </PopoverButton>

                      <transition
                          enter-active-class="transition duration-200 ease-out"
                          enter-from-class="translate-y-1 opacity-0"
                          enter-to-class="translate-y-0 opacity-100"
                          leave-active-class="transition duration-150 ease-in"
                          leave-from-class="translate-y-0 opacity-100"
                          leave-to-class="translate-y-1 opacity-0">
                        <PopoverPanel
                            style="width: max-content"
                            class="absolute  color_background_light rounded-lg z-10 mt-3 px-4 left-10/12 -translate-x-1/2 transform">
                          <!--                        absolute rounded-lg w-screen z-10 mt-3 left-1/2 -translate-x-1/2 transform px-4 -->
                          <div class=" ">
                            <div class="p-2 ">
                              <div class="flow-root  transition duration-150 ease-in-out">
                                <div class="items-center w-full py-2">
                                  <p class="text-sm font-medium color_enabled pb-2">
                                    {{ $t("str_error_details") }}
                                  </p>
                                  <p class="text-sm color_enabled ">
                                    {{
                                      $t(useConfig().config.configuration.error_lookup.filter((el: any) => el.id ===
                                          order['error_code'])[0]?.text) ?? $t("str_cannot_map_error_info")
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </PopoverPanel>
                      </transition>
                    </Popover>

                  </div>
                </td>
                <td>{{
                    order['priority'] ?? '-'
                  }}
                </td>
                <td>{{ formatTimeString(Date.parse(order['start_time']+'+00:00'), true) }}</td>
                <td>{{ formatTimeString(Date.parse(order['end_time']+'+00:00'), true) }}</td>
                <td class="align-end m-2 py-2 relative text-right">
                  <OptionsDropdown :options="[
                    {title: $t('str_order_options_show_full_route'), handler: () => showFullRouteModal(order)},
                    {title: $t('str_order_options_cancel'), handler: () => onCancelOrder(order)},
                    {title: $t('str_order_options_mark_as_canceled'), handler: () => markOrderAsCanceled(order)},
                    {title: $t('str_order_options_delete'), handler: () => confirm(() => onRemoveOrder(order)), hidden: !!useConfig().config.configuration.disable_order_deletion},
                  ]"></OptionsDropdown>
                </td>
                </tr>


                <!-- Display skeleton for initial orders loading -->
                <tr v-if="isInitialLoading" class="border-b-[1px] border_color_offwhite max-w-sm animate-pulse">
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:35px; margin-right: auto; margin-left: auto;"></div></td>
                  <td colspan="6"><div class="skeletonBox" style="width:75%;"></div></td>
                </tr>
                <tr v-if="isInitialLoading" class="border-b-[1px] border_color_offwhite max-w-sm animate-pulse">
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:35px; margin-right: auto; margin-left: auto;"></div></td>
                  <td colspan="6"><div class="skeletonBox" style="width:85%;"></div></td>
                </tr>
                <tr v-if="isInitialLoading" class="border-b-[1px] border_color_offwhite max-w-sm animate-pulse">
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:45px; margin-right: auto; margin-left: auto;"></div></td>
                  <td><div class="skeletonBox" style="width:35px; margin-right: auto; margin-left: auto;"></div></td>
                  <td colspan="6"><div class="skeletonBox" style="width:65%;"></div></td>
                </tr>
              </tbody>
            </table>

            <!-- No info message when there are no search (filtered) results -->
            <div v-if="isFiltersApplied && !isInitialLoading && !orders.size && !order_requests.size" class="w-full color_enabled px-5 py-5 text-center" >
              {{ $t('str_orders_no_data_found') }}
            </div>
          </div>
        </div>
        <div class="w-auto">
          <div v-if="(!isFiltersApplied || appliedFilters.view_list !== $t('str_filters_order_requests')) && orders.size" class="flex flex-row w-full justify-center">
            <div class="flex justify-center relative" style="align-items: center; justify-content:center">
              <button id="prevButton" @click="changePage(currentPage-1)" type="button" :disabled="currentPage <= 1 || isLoading"
                      class="color_enabled rounded h-12 w-12 mx-2 p-2 button2 ">&lt;
              </button>
              <span class="cursor-pointer inline-block mx-1 text-zinc-400 text-sm" @click="changePage(1)" v-if="currentPage - 4 >= 0">1</span>
              <span class="inline-block mx-[2px] text-zinc-400 text-xs"  v-if="currentPage - 4 > 0">...</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(1)"  v-if="currentPage - 3 === 0">1</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(currentPage - 2)"  v-if="currentPage - 2 > 1">{{ currentPage - 2 }}</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(currentPage - 1)"  v-if="currentPage - 1 > 0">{{ currentPage - 1 }}</span>
              
              <span class="inline-block color_enabled mx-2 p-2">{{ currentPage }} </span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(currentPage + 1)"  v-if="currentPage + 1 <= getNumPages()">{{ currentPage + 1 }}</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(currentPage + 2)"  v-if="currentPage + 2 <= getNumPages()">{{ currentPage + 2 }}</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(getNumPages())"  v-if="currentPage + 3 === getNumPages()">{{ getNumPages() }}</span>
              <span class="inline-block mx-[2px] text-zinc-400 text-xs"  v-if="currentPage + 4 <= getNumPages()">...</span>
              <span class="cursor-pointer inline-block mx-[2px] text-zinc-400 text-xs" @click="changePage(getNumPages())"  v-if="currentPage + 4 <= getNumPages()">{{ getNumPages() }}</span>

              <button id="nextButton" @click="changePage(currentPage+1)" type="button"
                      :disabled="currentPage > getNumPages() - 1 || isLoading"
                      class="color_enabled rounded h-12 w-12 mx-2 p-2 button2 ">>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
  <FullRoutePopup ref="fullRoutePopup"></FullRoutePopup>
</template>

<script setup lang="ts">
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/vue/20/solid'
import {FunnelIcon} from '@heroicons/vue/24/outline';
import {FunnelIcon as FunnelIconSolid} from '@heroicons/vue/24/solid';
import {Popover, PopoverButton, PopoverOverlay, PopoverPanel} from "@headlessui/vue";
import OptionsDropdown from '@/components/OptionsDropdown.vue';
import {formatTimeString} from "../utils";
import StyledInput from "../components/StyledInput.vue";
</script>

<script lang="ts">
import {defineComponent} from 'vue'
import {useConfig} from "../main";
import * as StorageManager from "../datamanagers/StorageManager";
import * as ApiManager from "../network/ApiManager";
import {publishInstantAction} from "@/network/MqttManager";
import {prod_safe_log} from '../utils'
import {orderFilterState} from '../datamanagers/ReactiveStates'
import * as AlertManager from '../datamanagers/AlertsManager'
import {ActionTypes} from "@/dtos/Actions";
import {vehicleStates} from '@/dtos/VehicleState';
import Dropdown from '@/components/Dropdown.vue';
import FullRoutePopup from '@/components/orders/FullRoutePopup.vue';
import DialogPopup from '@/components/DialogPopup.vue';
import {getAllOrderRequests} from "../network/ApiManager";
import {getPriorityStrings} from "../utils";

export default defineComponent({
  name: "OrdersView",
  data() {
    return {
      order_settings: useConfig().config.configuration.order,
      local_order_settings: useConfig().localConfig.order,
      orders: new Map(),
      order_requests: new Map(),
      orders_length: 0,
      currentPage: 1,
      sort_option: '',
      currentSortDir: useConfig().config.configuration.order.currentSortDir,
      sort_enabled: useConfig().config.configuration.order.sort_enabled,
      orderFilterState,
      vehicleStates,
      isLoading: false,
      isInitialLoading: true,
      vehicles: [],
      view_list: [
        this.$t("str_filters_all"),
        this.$t("str_filters_orders"),
        this.$t("str_filters_order_requests"),
      ],
      pagination_options: [
        10,
        20,
        50,
        100
      ],
      orders_pagination: useConfig().localConfig.order?.orders_page_size ?? useConfig().config.configuration.order.pageSize ?? 5,
      order_requests_pagination: useConfig().localConfig.order?.order_requests_page_size ?? useConfig().config.configuration.order.number_of_displayed_order_requests ?? 5,
      filtersForm: {
        vehicle_id: String(useConfig().localConfig.default_vehicle || ''),
        order_id_substr: '',
        order_request_id_substr: '',
        view_list: this.$t('str_filters_all'),
        node_substr: '',
        key_node_substr: '',
      },
      appliedFilters: {
        vehicle_id: String(useConfig().localConfig.default_vehicle || ''),
        order_id_substr: '',
        order_request_id_substr: '',
        view_list: this.$t('str_filters_all'),
        node_substr: '',
        key_node_substr: '',
      },
      isFiltersApplied: !!useConfig().localConfig.default_vehicle,
      showFiltersForm: false,
    }
  },
  computed: {
    getPriorityStringsWithCtx(){
      return getPriorityStrings(this)
    }
  },
  methods: {
    sort: function (s: any) {
      if (s === this.order_settings.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.currentSortDir = useConfig().config.configuration.order.currentSortDir
      }
      this.order_settings.currentSort = s;
      this.sort_option = (this.currentSortDir === 'asc' ? '' : '-') + s
      this.getAllOrders()
    },
    getAllVehicles() {
      let pathParams = ``; // generate extra path params here, if needed.
      ApiManager.getAllVehicles(pathParams,
        () => {
          // AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_orders"), this.$t("str_try_again")));
          prod_safe_log('vehicles could not be requested');
        },
        (data: any) => {
          this.vehicles = data.vehicles.map((val: any) => val);
        });
    },
    toggleFiltersForm() {
      this.showFiltersForm = !this.showFiltersForm;
      if (!this.showFiltersForm) {
        for (const i in Object.keys(this.appliedFilters)) {
          const key = Object.keys(this.appliedFilters)[i];
          //@ts-ignore
          if (this.appliedFilters[key]) {
            //@ts-ignore
            this.filtersForm[key] = this.appliedFilters[key];
          }
        }
      }
    },
    changeOrdersPagination(newQty: number) {
      this.orders_pagination = newQty;
      useConfig().updateLocalConfig({order: {...this.local_order_settings, orders_page_size: this.orders_pagination}})
    },
    getAllOrders(newPage?: number) {
      const targetPage = newPage || this.currentPage;
      let pathParams =
          `page_size=${this.orders_pagination}&page=${targetPage}`;

      for (const i in Object.keys(this.appliedFilters)) {
        const key = Object.keys(this.appliedFilters)[i];
        //@ts-ignore
        if (this.appliedFilters[key] && this.appliedFilters[key].length && key !== 'view_list') {
          //@ts-ignore
          pathParams += `&${key}=${this.appliedFilters[key]}`;
        }
      }
      // if (this.orderFilterState.processed_selected == true || this.orderFilterState.canceled_selected == true || this.sort_option !== '') {
      let extra_path = []
      //   if (this.orderFilterState.processed_selected == true) {
      //     extra_path.push(`processed=${this.orderFilterState.processed_selected}`)
      //   }
      //   if (this.orderFilterState.canceled_selected == true) {
      //     extra_path.push(`canceled=${this.orderFilterState.canceled_selected}`)
      //   }
      if (this.sort_option !== '') {
        let extra_path = []
        extra_path.push(`sort=${this.sort_option}`)
        pathParams = pathParams + '&' + extra_path.join('&')
      }
      //   
      // }
      this.isLoading = true;
      return new Promise((resolve, reject) => ApiManager.getAllOrders(pathParams,
        () => {
          AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_orders"), this.$t("str_try_again")))
          prod_safe_log('orders could not be requested')
          this.isLoading = false;
          this.isInitialLoading = false;
          reject('loading error');
        },
        (data: any) => {
          this.orders.clear();
          data.orders.reverse().map((order:any) => {
            order.just_updated = true;
            this.orders.set(order.orderId, order);
            setTimeout(() => {
              delete this.orders.get(order.orderId).just_updated;
            }, 1000);
          });
          this.orders_length = Number(data.length)
          this.isLoading = false;
          this.isInitialLoading = false;
          resolve('loading success');
        })
      );
    },
    getInstantActionCancel() {
      const instant_action_cancel: any[] = StorageManager.getAction(ActionTypes.CANCEL_ORDER)
      return instant_action_cancel?.at(0);
    },
    getAllOrderRequests() {
      //@ts-ignore
      if (this.appliedFilters.order_id_substr.length || this.appliedFilters.view_list === this.$t('str_filters_orders'))
        return this.order_requests.clear(); //Do not make an API request if there is a filter by order_id or show only orders is selected, since requests should not have one at all.

      let pathParams =
          `page_size=${this.order_requests_pagination}&page=1&sort=orderRequestId`
      if (!pathParams) {
        console.error("Path for order request is undefined, extra path: ", pathParams)
      }
      for (const i in Object.keys(this.appliedFilters)) {
        const key = Object.keys(this.appliedFilters)[i];
        //@ts-ignore
        if (this.appliedFilters[key] && this.appliedFilters[key].length && key !== 'view_list') {
          //@ts-ignore
          pathParams += `&${key}=${this.appliedFilters[key]}`;
        }
      }
        ApiManager.getAllOrderRequests(pathParams,
            () => {
              AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_orders"), this.$t("str_try_again")))
              prod_safe_log('orders could not be requested')
            },
            (data: any) => {
              this.order_requests.clear();
              data.order_requests.map((val: any) => this.order_requests.set(val.orderRequest.orderRequestId, val.orderRequest));
            });
    },
    onCancelOrder(order: any) {
      const instantActionMsg = this.getInstantActionCancel()
      publishInstantAction(useConfig().config.configuration.mqtt,
          window.location.hostname,
          window.location.protocol,
          order.vehicle_id,
          instantActionMsg)
    },
    async confirm(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: this.$t("str_confirm_deletion_title"),
        message: this.$t("str_confirm_deletion_message"),
        okButton: this.$t("str_confirm_deletion_btn_delete"),
        cancelButton: this.$t("str_confirm_deletion_btn_cancel"),
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
    onCancelOrderRequest(order_request: any) {
      ApiManager.cancelOrderRequest(order_request.orderRequestId, () => prod_safe_log('order request could not be cancelled'), (res:any)=>{});
    },
    onRemoveOrderRequest(order: any) {
        ApiManager.deleteOrderRequest(order.orderRequestId, () => prod_safe_log('order could not be removed'), (res:any)=>{});
    },
    onRemoveOrder(order: any) {
        ApiManager.deleteOrder(order.orderId, () => prod_safe_log('order could not be removed'), (res:any)=>{});
    },
    markOrderAsCanceled(order: { orderId: number }) {
      ApiManager.cancelOrder(order.orderId,
          () => console.log("Could not mark order as canceled"),
          (result: any) => console.log("Marked order as canceled"))
    },
    showFullRouteModal(order: any) {
      (this.$refs.fullRoutePopup as typeof FullRoutePopup).show(order);
    },
    getNumPages() {
      const num_pages = Math.floor(this.orders_length / this.order_settings.pageSize)
      return (this.orders_length % this.order_settings.pageSize == 0) ? num_pages : num_pages + 1
    },
    changePage(newPage: number) {
      const prevPage = this.currentPage;
      this.getAllOrders(newPage)
        .then(res => {
          this.currentPage = newPage;
        })
        .catch(err => {
          this.currentPage = prevPage;
        });
    },
    onOrderListUpdated(args?:any){
      if (args) {
        const data = JSON.parse(args.data)
        const msg = JSON.parse(data.message)
        prod_safe_log("fetched new order list")
        this.getAllOrders();
      }
    },
    onOrderRequestListUpdated(args?:any){
      if (args) {
        const data = JSON.parse(args.data)
        const msg = JSON.parse(data.message)
        prod_safe_log("fetched new order request list")
        this.getAllOrderRequests();
      }
    },
    onOrderDispatched(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (this.currentPage === 1 && !this.isFiltersApplied) { //new orders should only appear on the first page
        this.orders.set(Number(msg.order_id), msg.payload);
        while (this.orders.size > this.orders_pagination * 1.5) {
          const orderToRemoveFromList = Array.from(this.orders)[0];
          this.orders.delete(orderToRemoveFromList[0]);
        }
      }
    },
    onOrderCreatedWithErrors(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);

      if (this.currentPage === 1 && !this.isFiltersApplied) { //new orders should only appear on the first page
        this.orders.set(Number(msg.order_id), msg);
        while (this.orders.size > this.orders_pagination * 1.5) {
          const orderToRemoveFromList = Array.from(this.orders)[0];
          this.orders.delete(orderToRemoveFromList[0]);
        }
      }
    },
    onOrderUpdated(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (this.orders.has(Number(msg.order_id))) { // only update if there is a matching order in the list
        const updatedOrder = msg.payload;
        updatedOrder.just_updated = true;
        this.orders.set(Number(msg.order_id), updatedOrder);
        setTimeout(() => {
            delete this.orders.get(Number(msg.order_id)).just_updated;
        }, 1000)
      }
    },
    onOrderCanceled(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (this.orders.has(Number(msg.order_id))) { // only update if there is a matching order in the list
        const order = this.orders.get(Number(msg.order_id));
        order.end_time = msg.end_time;
        order.canceled = true;
        order.just_updated = true;
        setTimeout(() => {
            delete order.just_updated;
        }, 1000);
      }
    },
    onOrderCompleted(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (this.orders.has(Number(msg.order_id))) { // only update if there is a matching order in the list
        const order = this.orders.get(Number(msg.order_id));
        order.end_time = msg.end_time;
        order.just_updated = true;
        setTimeout(() => {
            delete order.just_updated;
        }, 1000);
      }
    },
    onOrderDeleted(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      // get orderID and remove it from the list if there is one matching
      this.orders.delete(Number(msg.order_id));
    },
    onOrderRequestAdded(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (!this.isFiltersApplied)
        this.order_requests.set(Number(msg.order_request_id), msg.payload);
    },
    onOrderRequestUpdated(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      if (this.order_requests.has(Number(msg.order_request_id)))
        this.order_requests.set(Number(msg.order_request_id), msg.payload);
    },
    onOrderRequestDeleted(args?: any) {
      if (!args) return;
      const data = JSON.parse(args.data);
      const msg = JSON.parse(data.message);
      this.order_requests.delete(Number(msg.order_request_id));
    },
    applyFilters(e?: SubmitEvent) {
      this.isFiltersApplied = false;
      for (const i in Object.keys(this.filtersForm)) {
        const key = Object.keys(this.filtersForm)[i];
        //@ts-ignore
        this.appliedFilters[key] = this.filtersForm[key];
        //@ts-ignore
        if (this.appliedFilters[key].length) {
          if (key === 'view_list') {
            if (this.appliedFilters.view_list !== this.$t('str_filters_all'))
              this.isFiltersApplied = true;
          } else {
            this.isFiltersApplied = true;
          }
        }
      }
      if (this.appliedFilters.view_list !== this.$t('str_filters_order_requests')){
        this.changePage(1);
      }
      this.getAllOrderRequests();
    },
    clearFilters() {
      this.filtersForm.vehicle_id = '';
      for (const i in Object.keys(this.appliedFilters)) {
        const key = Object.keys(this.appliedFilters)[i];
        this.appliedFilters.vehicle_id = '';
        //@ts-ignore
        if (this.appliedFilters[key] && this.appliedFilters[key].length) {
          //@ts-ignore
          this.appliedFilters[key] = '';
        }
        //@ts-ignore
        if (this.filtersForm[key] && this.filtersForm[key].length) {
          //@ts-ignore
          this.filtersForm[key] = '';
        }
      }
      this.filtersForm.view_list = this.$t('str_filters_all');
      this.appliedFilters.view_list = this.$t('str_filters_all');
      this.showFiltersForm = false;
      setTimeout(() => this.showFiltersForm = true, 0);
      this.isFiltersApplied = false;
      this.applyFilters();
    }
  },
  created() {
    this.getAllOrders();
    this.getAllOrderRequests();
    this.getAllVehicles();
    ApiManager.subscribeToUpdates([
      // [ApiManager.sse_message_types.order_list_updated, this.onOrderListUpdated],
      [ApiManager.sse_message_types.order_dispatched, this.onOrderDispatched],
      [ApiManager.sse_message_types.order_created_with_errors, this.onOrderCreatedWithErrors],
      [ApiManager.sse_message_types.order_updated, this.onOrderUpdated],
      [ApiManager.sse_message_types.order_canceled, this.onOrderCanceled],
      [ApiManager.sse_message_types.order_completed, this.onOrderCompleted],
      [ApiManager.sse_message_types.order_deleted, this.onOrderDeleted],
      // [ApiManager.sse_message_types.order_request_list_updated, this.onOrderRequestListUpdated],
      [ApiManager.sse_message_types.order_request_added, this.onOrderRequestAdded],
      [ApiManager.sse_message_types.order_request_updated, this.onOrderRequestUpdated],
      [ApiManager.sse_message_types.order_request_processed, this.onOrderRequestDeleted],
      [ApiManager.sse_message_types.order_request_deleted, this.onOrderRequestDeleted]
    ]);
  },
  mounted() {
  },
})

</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}

.table tbody tr td {
  white-space: pre-wrap
}
.header {
  /* background-color: #313D4C; */
  color: #819595;
}

.header:hover {
  /* background-color: #313D4C; */
  color: #F8F2DC;
  transition: 0.3s;
}

.fullscreen {
  top: 4.5rem;
  left: 0;
  bottom: 1rem;
  right: 0;
  overflow: auto;
  height: 100%;
}
.skeletonBox{
  height:28px;
  margin:19px 8px;
  background-color: rgba(255,255,255,0.15);
  border-radius: 8px;
}

.updated_blink{
  animation: bgPing 1s linear;
}

@keyframes bgPing {
  from {background-color: rgba(255,255,255,0.15)}
  to {background-color: rgba(255,255,255,0)}
}
</style>
