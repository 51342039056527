/**
 * Localization strings for the app. First english, then German.
 */

import {createI18n, Messages} from "vue3-i18n";

const messages: Messages = {
    en: {
        str_yes: 'Yes',
        str_no: 'No',
        str_control_center: "Control center",
        str_live_view: "Live View",
        str_vehicles_view: "Vehicle Overview",
        str_overview: "overview",
        str_jobs_view: "Orders",
        str_diagnosis_view: "Diagnosis",
        str_new_job: "New Order",
        str_failed_to_login: "Failed to login!",
        str_try_again: "Try again.",
        str_title_editing_mode: "Editing map",
        str_failed_to_activate_license: "Failed to activate the license!",
        str_can_not_submit_order: "Can't submit order!",
        str_order_should_have_2_nodes: "Order should have at least 2 nodes",
        str_access_restricted: "Access restricted",
        str_access_restricted_msg: 'If you are sure this is a mistake - contact your administrator to check your access level.',
        str_need_to_relogin: "Need to re-login to get fresh JWT token",
        str_need_to_relogin_msg: 'Please re-login and try again.',

        // Status view
        str_select_map: "Current map",
        str_error: "Error",
        str_localized: "Localized",
        str_show_node_labels: "Show node labels",
        str_show_vehicle_labels: "Show vehicle labels",
        str_mark_occupied_nodes_edges: "Mark occupied nodes/edges",

        str_attention: "Attention",
        str_new_config_available_msg: "New configuration available. Please update to the new version",
        str_new_config_saved_msg: "New configuration saved. Please refresh the page to apply changes",
        str_download_and_apply: "Download and apply",
        str_refresh_page: "Refresh Page",
        str_power_saving_mode: "Power Saving Mode",
        str_power_saving_on_description: "Energy saving mode shuts down all services, for the vehicles on the idle stations, except the state reporting. The shut down signal is sent after a timeout. The vehicle in energy saving mode needs about 2 minutes to start all services and accept new order.\n\nProceed with turning energy saving mode on?",
        str_power_saving_off_description: "Turning the energy saving mode off will send an action to the vehicles to start all the services and be ready to accept new order. This will take about 2 minutes.\n\nProceed with turning energy saving mode off?",
        str_power_saving_yes: "Yes",
        str_power_saving_cancel: "Cancel",

        // Barcodes List
        str_barcode: "Barcode",
        str_transportation_type: "Transportation Type",
        str_remaining_palletes: "Remaining Palletes",

        // Barcode Modal
        str_barcode_menu_title: "Barcode scans",
        str_forklift: "Forklift",
        str_barcode_error: "Error while trying to read barcode",
        str_error_text: [
            "No error",
            "Couldn't scan the barcode",
            "Labels on different sides do not match"
        ],
        str_barcode_option_agv: "AGV",
        str_barcode_modal_header: "New Barcode detected",
        str_barcode_modal_sub_header: "Please choose which vehicle should transport how many palettes with this barcode",

        // Node/Edge modal
        str_edit_node: "edit node",
        str_use_vehicle_location: "Use vehicle location",
        str_select_vehicle: "Vehicle to use",
        str_select_vehicle_prompt: "Select vehicle",
        str_read_location: "Read vehicle location",
        str_edit_edge: "edit edge",
        str_edge_description: "Edge description",
        str_node_description: "Node description",
        str_start_node: "Start node",
        str_end_node: "End node",
        str_edit_trajectory: "Edit trajectory",
        str_length: "Length",
        str_max_speed_for_vehicle: "Max. speed for vehicle",
        str_max_height_for_vehicle: "Max. height for vehicle",
        str_min_height_for_vehicle: "Min. height for vehicle",
        str_orientation: "Orientation",
        str_orientation_free: "Free",
        str_orientation_front: "Front",
        str_orientation_left_side: "Left Side",
        str_orientation_right_side: "Right Side",
        str_orientation_back: "Back",
        str_orientation_custom: "Custom",
        str_orientation_type: "Orientation type",
        str_rotation_allowed: "Rotation allowed",
        str_max_rotation_allowed: "Max. rotation speed",
        str_actions: "ACTIONS",
        str_action_type: "Action type",
        str_action_description: "Action description",
        str_blocking_type: "Blocking type",
        str_parameters: "Parameters",
        str_key: "Key",
        str_value: "Value",
        str_add_new_parameter: "Add new parameter",
        str_add_action: "Add action",
        str_save_edge: "Save edge",
        str_delete_edge: "Delete edge",
        str_node_position: "node position",
        str_allowed_deviation: "Allowed deviation",
        str_map: "Map",
        str_save_node: "Save node",
        str_delete_node: "Delete node",
        str_click_on_end_node: "Click on the end node",
        str_add_edge: "Add edge",
        str_instant_actions: "INSTANT ACTIONS",
        str_send_instant_action: "Send Instant Action",
        str_last_updated: "Updated",
        str_last_order_id: "Last order ID",
        str_last_node_id: "Last node ID",
        str_last_sequence_id: "Last sequence ID",
        str_last_driving: "Driving",
        str_last_paused: "Paused",
        str_last_new_base_request: "New base request",
        str_has_released_edges: "Released edges count",
        str_has_released_nodes: "Released nodes count",
        str_last_battery: "Battery",
        str_current_destination: ["Current destination", "Node"],
        str_find_on_map: "Find on map",
        str_show_on_map: "Show on map",
        str_connected_edges: "Connected edges",
        str_node: "Node",
        str_edge: "Edge",
        str_node_stations_title: "Stations",
        str_is_node_charging: "Charging station",
        str_station_priority: "Priority",
        str_is_node_idle: "Idle station",
        str_is_node_safe_zone_fire: "Fire alarm safe zone",
        str_priority_high: "High",
        str_priority_normal: "Normal",
        str_priority_low: "Low",
        str_unblock_node: "Unblock node",
        str_unblock_edge: "Unblock edge",
        str_extra_edge_cost: "Extra edge cost",
        str_component: "Component",
        str_connected_components: "Connected Components",
        str_new_component: "New Component",
        str_edit_component: "Edit component",
        str_create_component: "Create component",
        str_save: "Save",

        // New order modal
        str_new_order: "New Order",
        str_editing_order: "Editing Template",
        str_vehicle: "Vehicle",
        str_automatic: "Automatic",
        str_select_node: "Select Node",
        str_submit_order: "Submit order",
        str_custom_actions: "Custom actions ",
        str_default_actions: "Default actions ",
        str_save_order_template: "Save order template",
        str_create_order_template: "Create order template",
        str_quick_route_saved: "Successfully saved route",
        str_quick_route_not_saved: "Couldn't save route",
        str_quick_route_not_saved1: "Check your connection and try again",
        str_order_request_priority: "Priority",
        str_order_priority_values: ["Extra high", "High", "Normal", "Low", "Extra low"],

        // Orders view
        str_confirm_deletion_title: "Delete an Order",
        str_confirm_deletion_message: "Are you sure you want to delete this order? This action cannot be undone.",
        str_confirm_deletion_btn_delete: "Delete",
        str_confirm_deletion_btn_cancel: "Cancel",
        str_order_options_show_full_route: "Show full route",
        str_order_options_cancel: "Cancel",
        str_order_options_mark_as_canceled: "Mark as cancelled",
        str_order_options_delete: "Delete",
        str_apply_filters: "Apply Filters",
        str_clear_filters: "Clear Filters",
        str_filter_by: "Filter By",
        str_filters_show: "Show",
        str_filters_all: "All",
        str_filters_orders: "Orders",
        str_filters_order_requests: "Order Requests",
        str_filters_node: "Node",
        str_filters_key_node: "Key Node",
        str_orders_per_page: "Orders per page",
        str_vehicle_id: "Vehicle ID",
        str_select_vehicle_id: "Select Vehicle ID",
        str_bearbeitet: "processed",
        str_abgebrochen: "cancelled",
        str_order_status: "Status",
        str_order_errors: "Errors",
        str_request_id: "Request-ID",
        str_order_id: "Order-ID",
        str_route_id: "Route-ID",
        str_nodes: "nodes",
        str_edges: "edges",
        str_start_time: "Start time",
        str_end_time: "End time",
        str_error_fetching_orders: "Error updating order list",
        str_error_fetching_order: "Error updating order",
        str_error_fetching_order_request: "Error updating order request",
        str_order_canceled: "Canceled",
        str_order_failed: "Failed",
        str_order_completed: "Completed",
        str_order_in_progress: "In progress",
        str_order_request_queued: "Waiting",
        str_error_details: "Error details",
        str_order_error_invalid_node: "One of the entered nodes in the order doesn't exist, \nplease double check" +
            " that \nall the nodes in the order request are valid.",
        str_order_error_no_path: "One or more of the entered nodes in the order \naren't reachable from reported" +
            " vehicle position.",
        str_order_error_other: "An error occurred while creating an order. \nCheck the logs for more info.",
        str_cannot_map_error_info: "Error info not available",

        str_error_fetching_nodes: "Error updating node list",
        str_error_fetching_edges: "Error updating edge list",
        str_error_fetching_actions: "Error updating action list",
        str_error_adding_new_action: "Error adding new action",
        str_error_adding_new_node: "Error adding new node",
        str_error_adding_new_edge: "Error adding new edge",
        str_fill_form_correctly: "Please fill the form correctly and completely.",
        str_orders_no_data_found: "No data found with applied filters. Please adjust your filtering parameters.",

        // Full Route Popup
        str_full_route_for_order: 'Full route for Order',
        str_full_route_close: "Close",

        // Order templates
        str_confirm_order_template_deletion_title: "Delete a template",
        str_confirm_order_template_deletion_message: "Are you sure you want to delete this template?",
        str_confirm_order_template_deletion_btn_delete: "Delete",
        str_confirm_order_template_deletion_btn_cancel: "Cancel",
        str_menu_order_templates: "Saved Routes",
        str_order_templates_edit: "Edit",
        str_order_templates_delete: "Delete",
        str_confirm_order_from_template: "Create a new order from given node(s) for the selected vehicle?",
        str_cancel: "Cancel",
        str_edit_title: "Edit title",
        str_more: "More",
        str_matching_text: "Matching text",
        str_category: "Category",

        // Custom triggers
        str_custom_triggers_view: 'Custom triggers',

        // Vehicle overview
        str_vehicle_online: "Online",
        str_vehicle_offline: "Offline",
        str_vehicle_localized: "Localized",
        str_vehicle_not_localized: "Not Localized",
        str_active_order: "Active order",
        str_vehicle_status: "Vehicle status",
        str_vehicle_order: "Order",
        str_vehicle_order_update_id: "Order update ID",
        str_force_order_update: "Force order update",
        str_confirm_force_order_update_description: "Warning. Triggering force order update can disrupt other orders in progress. Are you sure you want to continue?",
        str_confirm_force_order_update_yes: "Yes",
        str_confirm_force_order_update_cancel: "Cancel",
        str_vehicle_edge_states: "Edge states",
        str_vehicle_node_states: "Node states",
        str_vehicle_action_states: "Action states",
        str_vehicle_node_id: "NodeID",
        str_vehicle_edge_id: "EdgeID",
        str_released: "Released",
        str_not_released: "Not released",
        str_vehicle_general_info: "General",
        str_vehicle_serial_number: "Serial number",
        str_vehicle_manufacturer: "Manufacturer",
        str_vehicle_protocol_version: "Protocol version",
        str_vehicle_operating_mode: "Operating mode",
        str_vehicle_battery_charge: "Battery charge",
        str_vehicle_position: "Position",
        str_vehicle_loads: "Loads",
        str_vehicle_map_id: "Map ID",
        str_vehicle_map_description: "Map description",
        str_vehicle_zone_set_id: "Zone set ID",
        str_vehicle_errors: "Errors",
        str_vehicle_no_errors: "No error messages",
        str_vehicle_error_description: "Description",
        str_vehicle_error_hint: "Hint",
        str_vehicle_info: "Info",
        str_vehicle_no_info: "No info messages",

        str_factsheet: "Factsheet",
        str_factsheet_type_specification: "Type Specification",
        str_factsheet_series_name: "Series name",
        str_factsheet_series_description: "Series description",
        str_factsheet_agv_kinematic: "AGV kinematics-type",
        str_factsheet_agv_class: "AGV class",
        str_factsheet_max_load: "Max allowed load mass",
        str_factsheet_localization_types: "Localization types",
        str_factsheet_navigation_types: "Navigation types",
        str_factsheet_physical_parameters: "Physical parameters",
        str_factsheet_speed_min: "Min speed",
        str_factsheet_speed_max: "Max speed",
        str_factsheet_acceleration_max: "Max acceleration",
        str_factsheet_deceleration_max: "Max deceleration",
        str_factsheet_height_min: "Min height",
        str_factsheet_height_max: "Max height",
        str_factsheet_width: "Width",
        str_factsheet_length: "Length",
        str_factsheet_protocol_limits: "Protocol limits",
        str_factsheet_protocol_features: "Protocol features",
        str_factsheet_agv_geometry: "Agv geometry",
        str_factsheet_load_specification: "Load specification",

        // User Management
        str_usermanagement_signin: 'Sign In',
        str_usermanagement_signout: 'Sign Out',
        str_usermanagement_username: 'Username',
        str_usermanagement_password: 'Password',
        str_usermanagement_password_is_too_short:'Password is too short',
        str_usermanagement_cancel: 'Cancel',
        str_usermanagement_user_management: 'User Management',
        str_usermanagement_account_settings: 'Account Settings',
        str_usermanagement_current_user: 'Current User',
        str_usermanagement_full_name: 'Full Name',
        str_usermanagement_email: 'Email',
        str_usermanagement_role: 'Role',
        str_usermanagement_change_password: 'Change Password',
        str_usermanagement_old_password: 'Old Password',
        str_usermanagement_new_password: 'New Password',
        str_usermanagement_repeat_new_password: 'Repeat New Password',
        str_usermanagement_submit_new_password: 'Submit',
        str_usermanagement_submit: 'Submit',
        str_usermanagement_confirm_signout: 'Are you sure you want to Sign Out?',
        str_usermanagement_loading: 'Loading',
        str_usermanagement_users_list: 'Users List',
        str_usermanagement_actions: 'Actions',
        str_usermanagement_edit: 'Edit',
        str_usermanagement_delete: 'Delete',
        str_usermanagement_delete_user: 'Delete User',
        str_usermanagement_delete_user_confirmation: 'Are you sure you want to delete user? This can not be undone.',
        str_usermanagement_create_new_user: 'Create New User',
        str_usermanagement_roles_list: 'Roles List',
        str_usermanagement_role_name: 'Role Name',
        str_usermanagement_access_level: 'Access Level',
        str_usermanagement_description: 'Description',
        str_usermanagement_create_new_role: 'Create New Role',
        str_usermanagement_delete_role: 'Delete Role',
        str_usermanagement_delete_role_confirmation: 'Are you sure you want to delete a role? This can not be undone. Please ensure a fallback role is selected.',

        // Administration Panel
        str_administration_control_panel: 'Administration Control Panel',
        str_extra_controls: 'Extra controls',
        str_update_actions_from_api: 'Update actions from API',
        str_update_configuration_from_api: 'Update configuration from API',
        str_editors: 'Editors',
        str_open_global_config_editor: 'Open Global Config Editor',
        str_open_local_config_editor: 'Open Local Config Editor',
        str_open_maps_editor: 'Open Maps Editor',
        str_apply_global_config: 'Apply Global Config',
        str_apply_local_config: 'Apply Local Config',
        str_revert_changes: 'Revert Changes',
        str_global_config_editor: 'Global Config editor',
        str_local_config_editor: 'Local Config editor',
        str_maps_editor: 'Maps editor',
        str_show_legend: 'Show Legend',
        str_hide_legend: 'Hide Legend',
        str_legend: 'Legend',
        str_locale_description: 'Set selected language',
        str_showVehicleLabels_description: 'Show Vehicle labels on map',
        str_showNodeLabels_description: 'Show Node labels on map',
        str_global_api_request_timeout_description: 'Timeout for all API requests',
        str_get_all_orders_timeout_description: 'Timeout for getAllOrders API requests',
        str_get_all_order_requests_timeout_description: 'Timeout for getAllOrderRequests API requests',
        str_get_all_vehicles_timeout_description: 'Timeout for getAllVehicles API requests',
        str_get_nodes_timeout_description: 'Timeout for getNodes API requests',
        str_nodes_pagination_description: 'Maximum quantity of nodes per API request',
        str_get_edges_timeout_description: 'Timeout for getEdges API requests',
        str_edges_pagination_description: 'Maximum quantity of edges per API request',
        str_sse_pulse_interval_description: 'Interval for the SSE Pulse feature',
        str_power_saving_available_description: 'Shows global control switch for the power saving mode',
        str_disable_order_deletion_description: 'Disable option to delete order or order request',
        str_sefault_vehicle_description: 'Default vehicle on this local UI',
        str_hard_vehicle_restriction_description: 'Set hard restriction to the default vehicle, if set to true - will allow to send commands only to the default vehicle for the UI',
        str_base_line_width_description: 'Width of the Edges on the Map',
        str_enable_vehicle_localization_description: "Enables vehicle localization from the UI",
        str_node_hit_radius_description: "Adjust Node hit area on the map (default = 0.01)",
        str_edge_hit_radius_description: "Adjust Edge hit area on the map (default = 0.005)",
        str_allow_custom_edge_orientation_description: "Enables 'Custom' option for the Edge orientation",
        str_save_map_configuration: 'Save Map Configuration',
        str_config_outdated_warning_msg: "WARNING! Stored global config is outdated. Please load the new configuration from the API to update. Changes will not be saved."
    },
    de: {
        str_yes: 'Ja',
        str_no: 'Nein',
        str_control_center: "Leitstelle",
        str_title_editing_mode: "Karte bearbeiten",
        str_live_view: "Live View",
        str_jobs_view: "Aufträge",
        str_vehicles_view: "Fahrzeugübersicht",
        str_overview: "übersicht",
        str_diagnosis_view: "Diagnose",
        str_new_job: "Neuer Auftrag",
        str_failed_to_login: "Anmeldung fehlgeschlagen!",
        str_try_again: "Erneut versuchen",
        str_failed_to_activate_license: "Aktivierung der Lizenz fehlgeschlagen!",
        str_can_not_submit_order: "Auftrag kann nicht erstellt werden!",
        str_order_should_have_2_nodes: "Ein gültiger Auftrag muss mindestens 2 Knoten enthalten",
        str_access_restricted: "Zugriff eingeschränkt",
        str_access_restricted_msg: "Wenn Sie sicher sind, dass dies ein Fehler ist - wenden Sie sich an Ihren Administrator, um Ihr Zugriffsniveau zu überprüfen.",
        str_need_to_relogin: "Müssen Sie sich erneut anmelden, um einen frischen JWT-Token zu erhalten",
        str_need_to_relogin_msg: "Bitte melden Sie sich erneut an und versuchen Sie es erneut.",

        // Status view
        str_select_map: "Aktuelle Karte",
        str_localized: "Lokalisiert",
        str_show_node_labels: "Knotenbeschriftungen anzeigen",
        str_show_vehicle_labels: "Fahrzeugbeschriftung anzeigen",
        str_mark_occupied_nodes_edges: "Belegte Knoten/Kanten markieren",

        // Node/Edge modal
        str_edit_node: "Knoten bearbeiten",
        str_use_vehicle_location: "FTF Standort verwenden",
        str_select_vehicle: "Zu verwendendes FTF",
        str_select_vehicle_prompt: "FTF auswählen",
        str_read_location: "FTF Standort ablesen",
        str_edit_edge: "Kante bearbeiten",
        str_edge_description: "Information zur Kante",
        str_node_description: "Information zum Knoten",
        str_start_node: "Start",
        str_end_node: "Ziel",
        str_edit_trajectory: "Trajectorie ändern",
        str_length: "Länge",
        str_max_speed_for_vehicle: "Max. Fahrgeschwindigkeit",
        str_max_height_for_vehicle: "Max. höhe des FTFs",
        str_min_height_for_vehicle: "Min. höhe des FTFs",
        str_orientation: "Orientierung",
        str_orientation_free: "Frei",
        str_orientation_front: "Vorne",
        str_orientation_left_side: "Linke Seite",
        str_orientation_right_side: "Rechte Seite",
        str_orientation_back: "Hinterseite",
        str_orientation_custom: "Benutzerdefiniert",
        str_orientation_type: "Orientierungstyp",
        str_rotation_allowed: "Rotieren erlaubt",
        str_max_rotation_allowed: "Max. Rotationsgeschwindigkeit",
        str_actions: "AKTIONEN",
        str_action_type: "Aktionstyp",
        str_action_description: "Information zur Aktion",
        str_blocking_type: "Blockierungstyp",
        str_parameters: "Parameter",
        str_key: "Key",
        str_value: "Value",
        str_add_new_parameter: "neuen Parameter hinzufügen",
        str_add_action: "neue Aktion hinzufügen",
        str_save_edge: "Kante speichern",
        str_delete_edge: "Kante löschen",
        str_node_position: "Knoten-Position",
        str_allowed_deviation: "Zulässige Abweichung",
        str_map: "Karte",
        str_save_node: "Knoten speichern",
        str_delete_node: "Knoten löschen",
        str_click_on_end_node: "Klicken Sie auf den End-Knoten",
        str_add_edge: "Kante hinzufügen",
        str_instant_actions: "SOFORTAKTIONEN",
        str_send_instant_action: "Sofortaktion senden",
        str_last_updated: "Aktualisiert",
        str_last_order_id: "Aktuelle Auftrags-ID",
        str_last_node_id: "Aktuelle Knoten-ID",
        str_last_sequence_id: "Aktuelle Sequenz-ID",
        str_last_driving: "Fahren",
        str_last_paused: "Pausiert",
        str_last_new_base_request: "Neue Anfrage für Basis",
        str_has_released_edges: "Freigegebene Kanten zählen",
        str_has_released_nodes: "Freigegebene Knoten zählen",
        str_last_battery: "Batterie",
        str_current_destination: ["Aktuelles Ziel", "Knoten"],
        str_find_on_map: "Auf der Karte finden",
        str_show_on_map: "Auf der Karte anzeigen",
        str_connected_edges: "Verbundene Kanten",
        str_node: "Knoten",
        str_edge: "Kante",
        str_node_stations_title: "Stationen",
        str_is_node_charging: "Ladestation",
        str_station_priority: "Priorität",
        str_is_node_idle: "Wartestation",
        str_is_node_safe_zone_fire: "Sicherheitsbereich bei Brandalarm",
        str_priority_high: "Hoch",
        str_priority_normal: "Normal",
        str_priority_low: "Niedrig",
        str_unblock_node: "Knoten entsperren",
        str_unblock_edge: "Kante entsperren",
        str_extra_edge_cost: "Kante zusätzliche Kosten",
        str_component: "Komponente",
        str_connected_components: "Verbundenen Komponenten",
        str_new_component: "Neue Komponente",
        str_edit_component: "Komponente bearbeiten",
        str_create_component: "Komponente erstellen",
        str_save: "Speichern",

        // New order modal
        str_new_order: "Neuer Auftrag",
        str_editing_order: "Vorlage Bearbeitung",
        str_vehicle: "FTF",
        str_automatic: "Automatik",
        str_select_node: "Knoten auswählen",
        str_submit_order: "Auftrag bestätigen",
        str_custom_actions: "Benutzerdefinierte Aktionen",
        str_default_actions: "Standardaktionen",
        str_save_order_template: "Auftragvorlage speichern",
        str_create_order_template: "Create order template",
        str_quick_route_saved: "Route erfolgreich gespeichert",
        str_quick_route_not_saved: "Route konnte nicht gespeichert werden",
        str_quick_route_not_saved1: "Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut",
        str_order_request_priority: "Priorität",
        str_order_priority_values: ["Extra hoch", "Hoch", "Normal", "Niedrig", "Extra niedrig"],

        // Orders view
        str_confirm_deletion_title: "Eine Bestellung löschen",
        str_confirm_deletion_message: "Sind Sie sicher, dass Sie diese Bestellung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        str_confirm_deletion_btn_delete: "Löschen",
        str_confirm_deletion_btn_cancel: "Abbrechen",
        str_order_options_show_full_route: "Volle Route anzeigen",
        str_order_options_cancel: "Stornieren",
        str_order_options_mark_as_canceled: "Als storniert markieren",
        str_order_options_delete: "Löschen",
        str_apply_filters: "Filter anwenden",
        str_clear_filters: "Filter löschen",
        str_filter_by: "Filtern nach",
        str_filters_show: "Anzeigen",
        str_filters_all: "Alle",
        str_filters_orders: "Aufträge",
        str_filters_order_requests: "Auftragsanfragen",
        str_filters_node: "Knoten",
        str_filters_key_node: "Schlüssel-Knoten",
        str_orders_per_page: "Aufträge pro Seite",
        str_vehicle_id: "Fahrzeug-ID",
        str_select_vehicle_id: "Wählen Sie Fahrzeug-ID",
        str_bearbeitet: "bearbeitet",
        str_abgebrochen: "abgebrochen",
        str_order_id: "Auftrags-ID",
        str_order_status: "Status",
        str_order_errors: "Fehler",
        str_request_id: "Request-ID",
        str_nodes: "Knoten",
        str_edges: "Kanten",
        str_start_time: "Startzeit",
        str_end_time: "Endzeit",
        str_error_fetching_orders: "Fehler beim Aktualisieren der Auftragsliste",
        str_order_canceled: "Abgebrochen",
        str_order_failed: "Fehlgeschlagen",
        str_order_completed: "Abgeschlossen",
        str_order_in_progress: "In Bearbeitung",
        str_order_request_queued: "In der Warteschlange",
        str_error_details: "Fehler Beschreibung",
        str_order_error_invalid_node: "Einer der eingegebenen Knoten in dem Auftrag existiert nicht.\n" +
            "Bitte überprüfen Sie noch einmal, ob alle Knoten \nin der Auftragsanfrage gültig sind.",
        str_order_error_no_path: "Einer oder mehrere der im Auftrag eingegebenen Knoten, \nsind von der angegebenen" +
            " Fahrzeugposition aus \n, nicht erreichbar.",
        str_order_error_other: "Beim Erstellen eines Auftrags ist ein Fehler aufgetreten. \nWeitere Informationen" +
            " finden Sie im Log.",
        str_cannot_map_error_info: "Fehlerinformationen sind nicht verfügbar",

        str_attention: "Aufmerksamkeit",
        str_new_config_available_msg: "Neue Konfiguration verfügbar. Bitte aktualisieren Sie auf die neue Version",
        str_new_config_saved_msg: "Neue Konfiguration gespeichert. Bitte aktualisieren Sie die Seite, um Änderungen anzuwenden",
        str_download_and_apply: "Herunterladen und anwenden",
        str_refresh_page: "Seite aktualisieren",
        str_power_saving_mode: "Energiesparmodus",
        str_power_saving_on_description: "Der Energiesparmodus schaltet alle Dienste für die Fahrzeuge an den Leerlaufstationen außer der Statusberichterstattung ab. Das Abschaltsignal wird nach einer Zeitüberschreitung gesendet. Das Fahrzeug im Energiesparmodus benötigt etwa 2 Minuten, um alle Dienste zu starten und neue Aufträge anzunehmen.\n\nMit dem Einschalten des Energiesparmodus fortfahren?",
        str_power_saving_off_description: "Durch das Ausschalten des Energiesparmodus wird eine Aktion an die Fahrzeuge gesendet, um alle Dienste zu starten und neue Aufträge anzunehmen. Dies dauert etwa 2 Minuten.\n\nMöchten Sie mit dem Ausschalten des Energiesparmodus fortfahren?",
        str_power_saving_yes: "Ja",
        str_power_saving_cancel: "Abbrechen",

        // Full Route Popup
        str_full_route_for_order: "Volle Route für Auftrag",
        str_full_route_close: "Schließen",

        // Barcodes List
        str_barcode: "Barcode",
        str_transportation_type: "Transportation Type",
        str_remaining_palletes: "Übrige Palleten",

        // Barcode Modal
        str_barcode_menu_title: "Barcode Scans",
        str_forklift: "Gabelstapler",
        str_barcode_error: "Fehler beim Lesen des Barcodes",
        str_error_text: [
            "Kein Fehler",
            "Barcode kann nicht gescannt werden",
            "Etiketten auf verschiedenen Seiten stimmen nicht überein"
        ],
        str_barcode_option_agv: "FTS",
        str_barcode_modal_header: "Neuer Barcode erkannt",
        str_barcode_modal_sub_header: "Bitte wählen Sie ein Fahrzeug und die Anzahl an Paletten die es transportieren soll",

        str_error_fetching_nodes: "Fehler beim Laden der Stationsliste!",
        str_error_fetching_edges: "Fehler beim Laden der Routenliste!",
        str_error_fetching_actions: "Fehler beim Laden der Aktionsliste!",
        str_error_adding_new_action: "Fehler beim Erstellen der enuen Aktion",
        str_error_adding_new_node: "Fehler beim Erstellen eines neuen Knoten",
        str_error_adding_new_edge: "Fehler beim Erstellen einer neuen Kante",
        str_fill_form_correctly: "Bitte die Angaben vervollständigen.",
        str_orders_no_data_found: "Keine Daten mit den angewendeten Filtern gefunden. Bitte passen Sie Ihre Filterparameter an.",

        // Order templates
        str_confirm_order_template_deletion_title: "Eine Vorlage löschen",
        str_confirm_order_template_deletion_message: "Sind Sie sicher, dass Sie diese Vorlage löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        str_confirm_order_template_deletion_btn_delete: "Löschen",
        str_confirm_order_template_deletion_btn_cancel: "Abbrechen",
        str_menu_order_templates: "Gespeicherte Routen",
        str_order_templates_edit: "Bearbeiten",
        str_order_templates_delete: "Löschen",
        str_confirm_order_from_template: "Ein neuen Auftrag aus Knoten erstellen?",
        str_cancel: "Abbrechen",
        str_edit_title: "Titel bearbeiten",
        str_more: "Mehr",
        str_matching_text: "Passender Text",
        str_category: "Kategorie",

        // Custom triggers
        str_custom_triggers_view: 'Custom triggers',

        // Vehicle overview
        str_vehicle_online: "Online",
        str_vehicle_offline: "Offline",
        str_vehicle_localized: "Lokalisiert",
        str_vehicle_not_localized: "Nicht lokalisiert",
        str_active_order: "Aktive Auftrag",
        str_vehicle_status: "Fahrzeugstatus",
        str_vehicle_order: "Auftrag",
        str_vehicle_order_update_id: "Auftragsaktualisierungs-ID",
        str_force_order_update: "Auftragsaktualisierung erzwingen",
        str_confirm_force_order_update_description: "Achtung. Das Auslösen einer erzwungenen Auftragsaktualisierung kann andere laufende Aufträge stören. Möchten Sie wirklich fortfahren?",
        str_confirm_force_order_update_yes: "Ja",
        str_confirm_force_order_update_cancel: "Abbrechen",
        str_vehicle_edge_states: "Kantenzustände",
        str_vehicle_node_states: "Knotenzustände",
        str_vehicle_action_states: "Aktionszustände",
        str_vehicle_node_id: "NodeID",
        str_vehicle_edge_id: "EdgeID",
        str_released: "Freigegeben",
        str_not_released: "Nicht freigegeben",
        str_vehicle_general_info: "Allgemein",
        str_vehicle_serial_number: "Seriennummer",
        str_vehicle_manufacturer: "Hersteller",
        str_vehicle_protocol_version: "Protokollversion",
        str_vehicle_operating_mode: "Betriebsmodus",
        str_vehicle_battery_charge: "Batterieladung",
        str_vehicle_position: "Position",
        str_vehicle_loads: "Beladung",
        str_vehicle_map_id: "Karten-ID",
        str_vehicle_map_description: "Kartenbeschreibung",
        str_vehicle_zone_set_id: "Zonensatz-ID",
        str_vehicle_errors: "Fehler",
        str_vehicle_no_errors: "Keine Fehlermeldungen",
        str_vehicle_error_description: "Beschreibung",
        str_vehicle_error_hint: "Hinweis",
        str_vehicle_info: "Info",
        str_vehicle_no_info: "Keine Infomeldungen",

        str_factsheet: "Factsheet",
        str_factsheet_type_pecification: "Typspezifikation",
        str_factsheet_series_name: "Serienname",
        str_factsheet_series_description: "Serienbeschreibung",
        str_factsheet_agv_kinematic: "AGV-Kinematiktyp",
        str_factsheet_agv_class: "AGV-Klasse",
        str_factsheet_max_load: "Maximal zulässige Lademasse",
        str_factsheet_localization_types: "Lokalisierungstypen",
        str_factsheet_navigation_types: "Navigationstypen",
        str_factsheet_physical_parameters: "Physikalische Parameter",
        str_factsheet_speed_min: "Mindestgeschwindigkeit",
        str_factsheet_speed_max: "Maximale Geschwindigkeit",
        str_factsheet_acceleration_max: "Maximale Beschleunigung",
        str_factsheet_deceleration_max: "Maximale Verzögerung",
        str_factsheet_height_min: "Mindesthöhe",
        str_factsheet_height_max: "Maximale Höhe",
        str_factsheet_width: "Breite",
        str_factsheet_length: "Länge",
        str_factsheet_protocol_limits: "Protokollgrenzen",
        str_factsheet_protocol_features: "Protokollfunktionen",
        str_factsheet_agv_geometry: "Agv-Geometrie",
        str_factsheet_load_pecification: "Spezifikation laden",

        // User Management
        str_usermanagement_signin: 'Anmelden',
        str_usermanagement_signout: 'Abmelden',
        str_usermanagement_username: 'Nutzername',
        str_usermanagement_password: 'Kennwort',
        str_usermanagement_password_is_too_short:'Kennwort ist zu kurz',
        str_usermanagement_cancel: 'Abbrechen',
        str_usermanagement_user_management: 'Benutzerverwaltung',
        str_usermanagement_account_settings: 'Account Einstellungen',
        str_usermanagement_current_user: 'Aktueller Benutzer',
        str_usermanagement_full_name: 'Vollständiger Name',
        str_usermanagement_email: 'Email',
        str_usermanagement_role: 'Rolle',
        str_usermanagement_change_password: 'Kennwort ändern',
        str_usermanagement_old_password: 'Altes Kennwort',
        str_usermanagement_new_password: 'Neues Kennwort',
        str_usermanagement_repeat_new_password: 'Neues Kennwort wiederholen',
        str_usermanagement_submit_new_password: 'Bestätigen',
        str_usermanagement_submit: 'Einreichen',
        str_usermanagement_confirm_signout: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
        str_usermanagement_loading: 'Wird geladen',
        str_usermanagement_users_list: 'Benutzerliste',
        str_usermanagement_actions: 'Aktionen',
        str_usermanagement_edit: 'Bearbeiten',
        str_usermanagement_delete: 'Löschen',
        str_usermanagement_delete_user: 'Benutzer löschen',
        str_usermanagement_delete_user_confirmation: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten? Das kann nicht rückgängig gemacht werden.',
        str_usermanagement_create_new_user: 'Neuen Benutzer erstellen',
        str_usermanagement_roles_list: 'Rollenliste',
        str_usermanagement_role_name: 'Rolle Name',
        str_usermanagement_access_level: 'Zugriffsebene',
        str_usermanagement_description: 'Beschreibung',
        str_usermanagement_create_new_role: 'Neue Rolle erstellen',
        str_usermanagement_delete_role: 'Rolle löschen',
        str_usermanagement_delete_role_confirmation: 'Sind Sie sicher, dass Sie eine Rolle löschen möchten? Das kann nicht rückgängig gemacht werden. Bitte stellen Sie sicher, dass eine Fallback-Rolle ausgewählt ist.',

        // Administration Panel
        str_administration_control_panel: "Verwaltungskontrollfeld",
        str_extra_controls: "Zusätzliche Steuerelemente",
        str_update_actions_from_api: "Aktionen von API aktualisieren",
        str_update_configuration_from_api: "Konfiguration von API aktualisieren",
        str_editors: "Editoren",
        str_open_global_config_editor: "Globalen Konfigurationseditor öffnen",
        str_open_local_config_editor: "Lokalen Konfigurationseditor öffnen",
        str_open_maps_editor: "Karteneditor öffnen",
        str_apply_global_config: "Globale Konfiguration anwenden",
        str_apply_local_config: "Lokale Konfiguration anwenden",
        str_revert_changes: "Änderungen zurücksetzen",
        str_global_config_editor: "Globaler Konfigurationseditor",
        str_local_config_editor: "Lokaler Konfigurationseditor",
        str_maps_editor: "Karteneditor",
        str_show_legend: "Legende anzeigen",
        str_hide_legend: "Legende ausblenden",
        str_legend: "Legende",
        str_locale_description: "Ausgewählte Sprache einstellen",
        str_showVehicleLabels_description: "Fahrzeugbeschriftungen auf Karte anzeigen",
        str_showNodeLabels_description: "Knotenbeschriftungen auf Karte anzeigen",
        str_global_api_request_timeout_description: "Zeitüberschreitung für alle API-Anfragen",
        str_get_all_orders_timeout_description: "Zeitüberschreitung für alle getAllOrders-API-Anfragen",
        str_get_all_order_requests_timeout_description: "Zeitüberschreitung für alle getAllOrderRequests-API-Anfragen",
        str_get_all_vehicles_timeout_description: "Zeitüberschreitung für alle getAllVehicles-API-Anfragen",
        str_get_nodes_timeout_description: "Zeitüberschreitung für getNodes-API-Anfragen",
        str_nodes_pagination_description: 'Maximale Anzahl von Knoten pro API-Anfrage',
        str_get_edges_timeout_description: "Zeitüberschreitung für getEdges-API-Anfragen",
        str_edges_pagination_description: 'Maximale Anzahl von Kanten pro API-Anfrage',
        str_sse_pulse_interval_description: 'Intervall für die SSE Pulse-Funktion',
        str_power_saving_available_description: 'Zeigt den globalen Steuerschalter für den Energiesparmodus',
        str_disable_order_deletion_description: 'Deaktivieren die Option zum Löschen einer Auftrag oder Auftraganfrage.',
        str_sefault_vehicle_description: 'Standardfahrzeug auf dieser lokalen Benutzeroberfläche',
        str_hard_vehicle_restriction_description: 'Legen Sie eine harte Einschränkung auf das Standardfahrzeug fest. Wenn Sie diese Einstellung auf „true“ setzen, können Sie Befehle nur an das Standardfahrzeug für die Benutzeroberfläche senden.',
        str_base_line_width_description: "Breite der Kanten auf der Karte",
        str_enable_vehicle_localization_description: "Ermöglicht die Fahrzeuglokalisierung über die Benutzeroberfläche",
        str_node_hit_radius_description: "Passen Sie den Knotentrefferbereich auf der Karte an (Standard = 0,01)",
        str_edge_hit_radius_description: "Passen Sie den Kantentrefferbereich auf der Karte an (Standard = 0,005)",
        str_allow_custom_edge_orientation_description: "Aktiviert die Option „Benutzerdefiniert“ für die Kantenorientierung",
        str_save_map_configuration: "Kartenkonfiguration speichern",
        str_config_outdated_warning_msg: "ACHTUNG! Gespeicherte globale Konfiguration ist veraltet. Bitte laden Sie die neue Konfiguration von der API, um sie zu aktualisieren. Änderungen werden nicht gespeichert."
    },
};

const i18n = createI18n({
    locale: "en",
    messages,
});

export default i18n;
